/* global Foundation, jQuery */

(function myobHeaderFactory($, Site, Foundation) {
  class MyobHeader {
    constructor(element, options) {
      this.$element = element;
      this.options = $.extend({}, MyobHeader.defaults, this.$element.data(), options);

      this._init();
      Foundation.registerPlugin(this, 'MyobHeader');
    }

    _init() {
      this.$toggle = this.$element.find('.toolbox-toggle');
      this.$search = $('[data-target=".search-bar"]');
      this.$searchField = $('.search-field');
      this.$searchBar = $('.search-bar');
      this.$searchReset = $('button[type="reset"]');
      this.$subscribe = $('[data-target=".subscribe-bar"]');
      this.$subscribeBar = $('.subscribe-bar');
      this.$subscribeField = $('.subscribe-field');
      this._events();
    }

    _events() {
      this.$toggle.on('click', event => {
        const target = $(event.currentTarget).data('target');
        const $target = $(target);

        this.$search.click(() => {
          if (this.$subscribeBar.hasClass('active')) {
            this.$subscribeBar.removeClass('active').css('display', 'none');
          }
        });

        this.$subscribe.click(() => {
          if (this.$searchBar.hasClass('active')) {
            this.$searchBar.removeClass('active').css('display', 'none');
          }
        });
        $($target).slideToggle(250).toggleClass('active');
      });

      this.$search.click(() => {
        this.$searchField.focus();
      });

      this.$searchReset.click(() => {
        this.$searchReset.fadeOut(250);
        this.$searchField.focus();
      });

      this.$subscribe.click(() => {
        this.$subscribeField.focus();
      });

      this.$searchField.keyup(() => {
        if (this.$searchField.val()) {
          this.$searchReset.fadeIn(250);
        } else {
          this.$searchReset.fadeOut(250);
        }
      });
    }

    destroy() {
      Foundation.unregisterPlugin(this);
    }
  }

  Foundation.plugin(MyobHeader, 'MyobHeader');
}(jQuery, window.Site, Foundation));
