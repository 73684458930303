/**
 * Purpose: A custom built tooltip utility plugin that ensues
 * the tooltip component functions according to expected tooltip
 * behaviour and be integrated with other components needing the tooltip.
 * The expected tooltip behaviour includes being able to display a tooltip
 * to the left, right, top or bottom of a target, on mouseover or hover events.
 */
import { registerPluginNames } from '../../assets/js/system/registrar';
import { isInViewport, isInScrollingViewport } from '../../assets/js/helpers/window-helper';

const directions = {
  top: 'top',
  right: 'right',
  left: 'left',
  bottom: 'bottom',
};

const preferredAlternateDirections = {
  [directions.top]: [directions.bottom, directions.left, directions.right],
  [directions.bottom]: [directions.top, directions.left, directions.right],
  [directions.left]: [directions.right, directions.bottom, directions.top],
  [directions.right]: [directions.left, directions.bottom, directions.top],
};

const defaults = {
  tooltipText: 'tooltip',
  tooltipDirection: directions.bottom,
  tooltipHideForTouchScreen: false
};

let toolTipContainer;
let currentElement;
const toolTipClassName = 'tooltip';
let userIsTouching = false;

/**
 *  Used to create the tooltip dom element
 */
const createTooltip = elementDefaults => {
  toolTipContainer = document.createElement('div');
  toolTipContainer.className = toolTipClassName;
  toolTipContainer.setAttribute('direction', elementDefaults.tooltipDirection);
  const toolTipDiv = document.createElement('div');
  toolTipDiv.className = 'tooltip__bubble';
  toolTipDiv.innerText = elementDefaults.tooltipText;
  toolTipContainer.appendChild(toolTipDiv);
  return toolTipContainer;
};

/**
 * Gets x and y translation for the specified direction
 * @param  {Node} element   Element triggering tooltip
 * @param  {Node} tooltip   Tooltip element
 * @param  {String} direction Specified direction
 * @return {object}           X and Y tranlation
 */
const getTranslationForDirection = (element, tooltip, direction) => {
  const elemRect = element.getBoundingClientRect();
  const toolTipRect = tooltip.getBoundingClientRect();

  let xTranslate = null;
  let yTranslate = null;

  switch (direction) {
    case directions.top:
      yTranslate = elemRect.top - toolTipRect.bottom;
      xTranslate = elemRect.left + (elemRect.width / 2) - (toolTipRect.width / 2);
      break;
    case directions.left:
      yTranslate = elemRect.top + (elemRect.height / 2) - (toolTipRect.height / 2) - toolTipRect.top;
      xTranslate = elemRect.left - toolTipRect.width;
      break;
    case directions.right:
      yTranslate = elemRect.top + (elemRect.height / 2) - (toolTipRect.height / 2) - toolTipRect.top;
      xTranslate = elemRect.left + elemRect.width;
      break;
    default:
      yTranslate = elemRect.bottom - toolTipRect.top;
      xTranslate = elemRect.left + (elemRect.width / 2) - (toolTipRect.width / 2);
  }
  return {
    xTranslate: Math.floor(xTranslate),
    yTranslate: Math.floor(yTranslate),
  };
};

/**
 * Gets x and y translation and arrow direction for tooltip,
 * ensuring that it fits inside the viewport
 * @param  {Node} element   Element triggering tooltip
 * @param  {Node} tooltip   Tooltip element
 * @param  {String} direction Preferred direction
 * @return {object}           X and Y tranlation and direction to fit in viewport
 */
const getTranslation = (element, tooltip, direction) => {
  let tooltipDirection = direction;
  let { xTranslate, yTranslate } = getTranslationForDirection(element, tooltip, direction);
  const {
    top, left, right, bottom
  } = tooltip.getBoundingClientRect();

  // Check if tooltip is within the visible viewport,
  // else try alternative directions
  let inView = isInViewport({
    top: top + yTranslate, left: left + xTranslate, right: right + xTranslate, bottom: bottom + yTranslate
  });
  let altDirections = [...preferredAlternateDirections[direction]];
  while (!inView && altDirections.length > 0) {
    const altDirection = altDirections.shift();
    const { xTranslate: altX, yTranslate: altY } = getTranslationForDirection(element, tooltip, altDirection);
    inView = isInViewport({
      top: top + altY, left: left + altX, right: right + altX, bottom: bottom + altY
    });
    if (inView) {
      xTranslate = altX;
      yTranslate = altY;
      tooltipDirection = altDirection;
    }
  }

  // If tooltip still doesn't fit in the viewport,
  // try and fit it inside the existing scrollable document
  if (!inView) {
    altDirections = [...preferredAlternateDirections[direction]];
    while (!inView && altDirections.length > 0) {
      const altDirection = altDirections.shift();
      const { xTranslate: altX, yTranslate: altY } = getTranslationForDirection(element, tooltip, altDirection);
      inView = isInScrollingViewport({
        top: top + altY, left: left + altX, right: right + altX, bottom: bottom + altY
      });
      if (inView) {
        xTranslate = altX;
        yTranslate = altY;
        tooltipDirection = altDirection;
      }
    }
  }
  return { xTranslate, yTranslate, direction: tooltipDirection };
};

/**
 *  Set the coordinates for the tooltip based on the trigger
 */
const setCoordinates = element => {
  const { xTranslate, yTranslate, direction } = getTranslation(element, toolTipContainer, toolTipContainer.getAttribute('direction'));

  // Add class for direction to show arrow in correct orientation
  const directionClasses = {
    [directions.top]: 'tooltip--top',
    [directions.left]: 'tooltip--left',
    [directions.right]: 'tooltip--right',
    [directions.bottom]: 'tooltip--bottom',
  };

  Object.entries(directionClasses).forEach(([classDirection, dirClass]) => {
    if (classDirection === direction) {
      toolTipContainer.classList.add(dirClass);
    } else {
      toolTipContainer.classList.remove(dirClass);
    }
  });
  toolTipContainer.style.willChange = 'transform'; // https://css-tricks.com/almanac/properties/w/will-change/
  toolTipContainer.style.WebkitTransform = `translate(${xTranslate}px, ${yTranslate}px)`;
  toolTipContainer.style.msTransform = `translate(${xTranslate}px, ${yTranslate}px)`;
  toolTipContainer.style.transform = `translate(${xTranslate}px, ${yTranslate}px)`;
  toolTipContainer.style.willChange = '';
};

const hide = () => {
  if (toolTipContainer) {
    document.body.removeChild(toolTipContainer);
    currentElement = null;
    toolTipContainer = null;
  }
};

const show = element => {
  if ((currentElement) && (currentElement !== element)) {
    hide();
  }
  toolTipContainer = createTooltip(element.defaults);
  document.body.appendChild(toolTipContainer);
  setCoordinates(element.triggers);
  currentElement = element.triggers;
};

/**
 *  Hide the tooltip if click outside the trigger
 */
const checkForOutsideClick = event => {
  if ((currentElement) && (!currentElement.contains(event.target))) {
    hide();
  }
};

const addEventListeners = (elementData, action) => {
  if (action === 'hover') {
    Array.from(elementData).forEach(element => {
      element.triggers.addEventListener('mouseover', event => {
        if (userIsTouching) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          show(element);
        }
      });
      element.triggers.addEventListener('mouseleave', hide.bind(this, element));
    });
  }

  if (action === 'click') {
    Array.from(elementData).forEach(element => {
      element.triggers.addEventListener('click', () => {
        if (!element.defaults.tooltipHideForTouchScreen) {
          show(element);
        }
      });
    });
    document.addEventListener('touchstart', event => checkForOutsideClick(event));
  }
};

const getDefaults = elemDataSet => {
  const newDataSet = {};
  Object.keys(elemDataSet).forEach(key => {
    if (elemDataSet[key] !== null) {
      newDataSet[key] = elemDataSet[key];
    }
  });
  const originalDataSet = { ...defaults };
  return Object.assign(originalDataSet, newDataSet);
};

const constructElementDataSet = element => {
  const tooltipText = element.getAttribute('data-tooltip-text');
  const tooltipDirection = element.getAttribute('data-tooltip-direction');
  const tooltipHideForTouchScreen = element.hasAttribute('data-tooltip-hide-for-touchscreen');
  return { tooltipText, tooltipDirection, tooltipHideForTouchScreen };
};

const init = elements => {
  toolTipContainer = null;
  currentElement = null;
  userIsTouching = false;

  const elementData = Array.from(elements).map(element => ({
    triggers: element,
    defaults: getDefaults(constructElementDataSet(element))
  }));

  addEventListeners(elementData, 'hover');
  // Check if user is touching screen, credit: https://codeburst.io/the-only-way-to-detect-touch-with-javascript-7791a3346685
  window.addEventListener('touchstart', function onFirstTouch() {
    addEventListeners(elementData, 'click');
    userIsTouching = true;
    window.removeEventListener('touchstart', onFirstTouch, false);
  }, false);
  window.addEventListener('resize', hide, false);
};

export default init;
export {
  defaults,
  preferredAlternateDirections,
  createTooltip,
  getTranslationForDirection,
  getTranslation,
};

registerPluginNames(init, 'tooltip');
