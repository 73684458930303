/* global $ */

class MyobNavigationPayments {
  constructor() {
    this.$element = $('.navigation-payments');
    this.isTouchDevice = false;

    if (this.$element && this.$element.length > 0) {
      this._init();
    }
  }

  _init() {
    this.$dropdown = this.$element.find('.navigation-payments__dropdown');
    this.$hamburgerTrigger = this.$element.parent().find('[data-hamburger-trigger]');
    this.$dropdownTrigger = this.$element.find('[data-navigation-trigger]');
    this.$dropdownContainer = this.$element.find('.navigation-payments__panel');

    this._events();
  }

  checkAndHideActive() {
    this.$element.find('.is-active').removeClass('is-active');
  }

  static whichAnimationEvent(element) {
    const animations = {
      animation: 'animationend',
      OAnimation: 'oAnimationEnd',
      MozAnimation: 'animationend',
      WebkitAnimation: 'webkitAnimationEnd',
    };

    const animation = Object.entries(animations).find(
      entry => element.style[entry[0]] !== undefined
    );
    return animation[1];
  }

  static toggleActive($trigger, $target, $container) {
    $trigger.toggleClass('is-active').siblings().removeClass('is-active is-animating');
    $trigger.attr('aria-expanded', (index, attributeValue) => (attributeValue === 'true' ? 'false' : 'true'));
    $target.addClass('is-animating').siblings().removeClass('is-active is-animating');

    const isFirstLevel = $trigger.closest('nav').hasClass('navigation-payments__primary');

    if ($container && isFirstLevel) {
      $target.find('.is-active').removeClass('is-active');

      // Center the dropdown beneath the triggering menu item when not in mobile mode
      $target.removeAttr('style');
      if (!MyobNavigationPayments.isMobileView()) {
        const triggerPosition = $trigger.offset().left - $target.parent().offset().left;
        const triggerCenter = triggerPosition + ($trigger.width() / 2);
        const xPosition = triggerCenter - ($target.width() / 2);
        if (xPosition > 0) { // But not if it will push it off-screen
          $target.css('transform', `translateX(${xPosition}px)`);
        }
      }
    }

    $target.one(MyobNavigationPayments.whichAnimationEvent($target[0]), () => {
      $target.removeClass('is-animating');

      if ($trigger.hasClass('is-active')) {
        $target.addClass('is-active');
      } else {
        $target.removeClass('is-active');
      }
    });
  }

  static isMobileView() {
    const mobileScreenWidth = 992;
    let isMobileView = false;

    if (window.innerWidth <= mobileScreenWidth) {
      isMobileView = true;
    }

    return isMobileView;
  }

  static toggleActiveChildren($container, $excludedTarget) {
    $container.find('.navigation-payments__link.is-active').each((idx, ele) => {
      const $childTrigger = $container.find(`[data-navigation-trigger="${$(ele).data('navigationTrigger')}"]`);
      const $childTarget = $container.find(`[data-navigation-content="${$(ele).data('navigationTrigger')}"]`);

      if (!$childTarget.is($excludedTarget)) {
        MyobNavigationPayments.toggleActive($childTrigger, $childTarget);
      }
    });
  }

  startDropdownCloseTimer() {
    this.closeDropdownTimeout = setTimeout(() => {
      this.checkAndHideActive();
    }, 50);
  }

  stopDropdownCloseTimer() {
    clearTimeout(this.closeDropdownTimeout);
  }

  _events() {
    this.$hamburgerTrigger.on('click', event => {
      const $target = this.$element.parent().find(`[data-hamburger-content="${$(event.currentTarget).data('hamburger-trigger')}"]`);
      MyobNavigationPayments.toggleActiveChildren(this.$element, $target);
      MyobNavigationPayments.toggleActive($(event.currentTarget), $target, this.$element);
    });

    // To avoid hover states on a 'click' event for touch devices
    this.$dropdownTrigger.on('touchstart', () => {
      this.isTouchDevice = true;
    });

    this.$dropdownTrigger.mouseenter(event => {
      event.preventDefault();
      if (!this.isTouchDevice && !MyobNavigationPayments.isMobileView()) {
        this.stopDropdownCloseTimer();
        $(event.currentTarget).click();
      }
    });

    this.$dropdownTrigger.on('click', event => {
      event.preventDefault();
      const $target = this.$element.find(`[data-navigation-content="${$(event.currentTarget).data('navigation-trigger')}"]`);
      MyobNavigationPayments.toggleActiveChildren(this.$element, $target);
      MyobNavigationPayments.toggleActive($(event.currentTarget), $target, this.$element);
      this.isTouchDevice = false;
    });

    this.$dropdownTrigger.add(this.$dropdownContainer).mouseleave(() => {
      if (!this.isTouchDevice && !MyobNavigationPayments.isMobileView()) {
        this.startDropdownCloseTimer();
      }
    });

    this.$dropdownContainer.mouseenter(() => {
      this.stopDropdownCloseTimer();
    });

    ['load', 'resize'].forEach(event => {
      window.addEventListener(event, () => {
        if (window.Site.debounce) {
          const DOMChange = window.Site.debounce(() => {
            this.checkAndHideActive();
          }, 250);

          DOMChange();
        } else {
          this.startDropdownCloseTimer();
        }
      });
    });
  }
}

export default new MyobNavigationPayments();
