class ProductCard {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const googleFormattedData = {};
    switch (this.event) {
      case 'productCardTrialClick':
        // Fall through
      case 'productCardBuyClick':
      {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.productLabel = this.data.attributes.productLabel;
        googleFormattedData.currencyLabel = this.data.attributes.currencyLabel;
        googleFormattedData.priceLabel = this.data.attributes.priceLabel;
        googleFormattedData.paymentPeriodLabel = this.data.attributes.paymentPeriodLabel;
        googleFormattedData.pricingBoxBtnLabel = this.data.attributes.pricingBoxBtnLabel;
        googleFormattedData.pricingBoxBtnUrl = this.data.attributes.pricingBoxBtnUrl;
        break;
      }

      default:
        throw Error(`Event ${this.event} is not registered`);
    }
    return googleFormattedData;
  }
}

export default ProductCard;
