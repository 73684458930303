/* global Foundation, jQuery */

import ProductsHelper from '../../assets/js/helpers/products-helper';
import AnalyticsHelper from '../../assets/js/helpers/analytics/analytics-helper';

(function myobPricingCalculatorFactory($, Foundation) {
  class MyobPricingCalculator {
    constructor(element) {
      this.$element = element;
      this.productsHelper = new ProductsHelper();
      this.cache = {};
      this._init();
      Foundation.registerPlugin(this, 'MyobPricingCalculator');
    }

    _init() {
      this.analyticsHelper = new AnalyticsHelper();

      this.frequency = 'monthly';
      this.employees = parseInt(this.$element.find('.pricing-calculator__employees').val(), 10) || 1;
      this.updateCalculatorBox();
      this._events();
      this.min = parseInt(this.$element.attr('min') || 1, 10);
      this.max = parseInt(this.$element.attr('max') || 7000000, 10);
      // Billing is always per month for products using this calculator
      this.$element.find('.price__frequency').text('/mo');
    }

    currencyFormat = num => num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    getEmployees() {
      if (isNaN(this.employees)) { // eslint-disable-line no-restricted-globals
        this.employees = 0;
      }
      return this.employees;
    }

    updateEmployees() {
      this.employees = parseInt(this.$element.find('.pricing-calculator__employees').val(), 10);
      this.updateCalculatorBox();
    }

    updateCalculatorBox() {
      const that = this;
      const country = this.$element.data('country');
      const productId = this.$element.data('product');
      const productData = that.getCache(country, productId);
      if (productData) {
        that.calculatePrice(productData);
      } else {
        that.$element.find('.progress-indicator').show();

        that.$element.find('.price__currency').text('');
        that.$element.find('.price__gst').text('');
        that.$element.find('.pricing-calculator__terms.small').text('');
        that.$element.find('.price .price__amount').text('');

        this.productsHelper
          .fetchProduct(country, productId)
          .then(result => {
            that.$element.find('.progress-indicator').hide();
            that.$element.find('.price').show();
            that.saveCache(country, productId, result);
            that.calculatePrice(result);
          })
          .catch(() => {
            that.$element.find('.progress-indicator').hide();
            that.$element
              .find('.pricing-calculator__terms.small')
              .text(
                "We couldn't find a price for your selection. Try again, or give our support team a call and they'll help you out."
              );
          });
      }
    }

    getCache(country, productId) {
      return this.cache[this.generateCacheKey(country, productId)] || null;
    }

    generateCacheKey = (country, productId) => `${country}|${productId}`;

    saveCache(country, productId, productData) {
      if (country && productId && productData) {
        this.cache[this.generateCacheKey(country, productId)] = productData;
      }
    }

    calculatePrice(product) {
      const productId = this.$element.data('product');
      const $price = this.$element.find('.price');
      const $priceAmount = $price.find('.price__amount');
      const $priceDecimal = $price.find('.price__decimal');

      const {
        terms,
        price: {
          minEmployees,
          currencySymbol,
          unitPriceMonth: essentialsPayrollPrice,
          GST: essentialsPayrollGST,
        },
        trialURL: essentialsPayrollTrialURL,
        trialCTA: essentialsPayrollTrialCTA,
        purchaseURL: essentialsPayrollPurchaseURL,
        purchaseCTA: essentialsPayrollPurchaseCTA,
      } = product;

      let renderPrice = 0;
      let mainAmount = '';
      let decimalamount = '';
      let wholeAmount = [];

      this.$element.find('.price__currency').text(currencySymbol);
      this.$element.find('.price__gst').text(essentialsPayrollGST);
      this.$element.find('.pricing-calculator__terms.small').text(terms);
      this.$element
        .find('.btn--primary')
        .text(essentialsPayrollTrialCTA)
        .attr('href', essentialsPayrollTrialURL);
      this.$element
        .find('.btn--bordered-mono')
        .text(essentialsPayrollPurchaseCTA)
        .attr('href', essentialsPayrollPurchaseURL);

      // Look up for the minimum number of weeks/fortnights/months in a month
      const minInMonth = {
        weekly: 4,
        fortnightly: 2,
        monthly: 1,
      };

      switch (productId) {
        case 'EP-BND-NZ': { // Essentials Starter NZ
          const additionalEmployees = (this.employees > minEmployees) ? this.employees - minEmployees : 0;
          renderPrice = essentialsPayrollPrice + (additionalEmployees * minInMonth[this.frequency]);
          break;
        }

        default:
          renderPrice = essentialsPayrollPrice;
      }
      wholeAmount = renderPrice
        .toFixed(2)
        .toString()
        .split('.');
      [mainAmount] = wholeAmount;
      [, decimalamount] = wholeAmount;
      $priceAmount.text(mainAmount);
      $priceDecimal.text(`.${decimalamount || '00'}`);
      this.$element.find('.pricing-calculator__employees').val(this.employees);

      if (renderPrice > 9999.0) {
        if (!$price.hasClass('price--sm')) {
          $price.addClass('price--sm');
        }
      } else if ($price.hasClass('price--sm')) {
        $price.removeClass('price--sm');
      }
    }

    _events() {
      const that = this;
      const $trialCTA = this.$element.find('#htmlId-trial-link');
      const $buyCTA = this.$element.find('#htmlId-buy-link');

      this.$element.find('.tabs').on('change.zf.tabs', (ele, target) => {
        if ($(target)[0].dataset.value) {
          that.frequency = $(target)[0].dataset.value;
          if (isNaN(that.employees)) { // eslint-disable-line no-restricted-globals
            that.employees = 1;
          }
          that.analyticsHelper.record('AEM:pricingCalculator:frequencyChange', {
            eventInfo: {
              eventName: 'Calculator',
              eventAction: 'click',
            },
            attributes: {
              label: that.frequency,
              frequency: that.frequency,
              category: 'EssCalculator',
              value: that.getEmployees(),
            },
          });
        }
        that.updateCalculatorBox();
      });

      $buyCTA.on('click', () => {
        that.analyticsHelper.record('AEM:pricingCalculator:buyClick', {
          eventInfo: {
            eventName: 'Calculator',
            eventAction: 'click',
          },
          attributes: {
            label: $buyCTA.text(),
            frequency: that.frequency,
            category: 'EssCalculator',
            value: that.getEmployees(),
          },
        });
      });

      $trialCTA.on('click', () => {
        that.analyticsHelper.record('AEM:pricingCalculator:trialClick', {
          eventInfo: {
            eventName: 'Calculator',
            eventAction: 'click',
          },
          attributes: {
            label: $trialCTA.text(),
            frequency: that.frequency,
            category: 'EssCalculator',
            value: that.getEmployees(),
          },
        });
      });

      this.$element.find('.pricing-calculator__employees').on({
        change: () => {
          that.updateEmployees();

          that.analyticsHelper.record('AEM:pricingCalculator:employeeChange', {
            eventInfo: {
              eventName: 'Calculator',
              eventAction: 'update',
            },
            attributes: {
              label: 'update',
              frequency: that.frequency,
              category: 'EssCalculator',
              value: that.getEmployees(),
            },
          });
        },
        keyup: function keyUp() {
          that.updateEmployees();
        },
      });

      this.$element
        .find('.pricing-calculator__employees')
        .on('keydown input propertychange keyup', function changeEmployeeValue(e) {
          if (
            $.inArray(e.keyCode, [46, 8, 9, 27]) !== -1
            || (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true))
            || (e.keyCode >= 35 && e.keyCode <= 40)
            || e.keycode === 27
          ) {
            return;
          }
          if (
            e.shiftKey
            || ((e.keyCode < 48 || e.keyCode > 57)
              && (e.keyCode < 96 || e.keyCode > 105 || e.keyCode === 110 || e.keycode === 190))
          ) {
            e.preventDefault();
          }
          if (parseInt(this.value, 10) < that.min) {
            this.value = '';
          }
          if (parseInt(this.value, 10) > that.max) {
            this.value = that.max;
          }
        });
    }
  }

  Foundation.plugin(MyobPricingCalculator, 'MyobPricingCalculator');
}(jQuery, Foundation));
