const plugins = {};
export const registerPluginNames = (fn, pluginName) => {
  plugins[pluginName] = fn;
};

export default () => {
  Object.keys(plugins).forEach(pluginName => {
    const element = document.querySelectorAll(`[data-myob-${pluginName}]`);
    plugins[pluginName](element);
  });
};
