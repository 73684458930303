/* global Foundation, jQuery */

import 'foundation-sites/js/foundation.toggler';
import 'foundation-sites/js/foundation.util.motion';
import 'foundation-sites/js/foundation.util.triggers';

(function myobOutdatedBrowserFactory($, Foundation) {
  class MyobOutdatedBrowser extends Foundation.Toggler {
    constructor(element, options) {
      element.data('toggler', element.data('myobOutdatedBrowser'));
      element.attr('data-toggle', '');
      const overrideOptions = $.extend({}, MyobOutdatedBrowser.defaults, element.data(), options);

      super(element, overrideOptions);
      this.options = overrideOptions;

      Foundation.registerPlugin(this, 'MyobOutdatedBrowser');
    }

    _events() {
      super._events();
    }
  }

  MyobOutdatedBrowser.defaults = {
    animate: false,
    componentClass: 'outdated-browser',
  };

  Foundation.plugin(MyobOutdatedBrowser, 'MyobOutdatedBrowser');
}(jQuery, Foundation));
