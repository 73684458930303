/* global $ */

import ScrollMagic from 'scrollmagic';

// init controller
const controller = new ScrollMagic.Controller();
const revisedRandId = () => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
const uniqueID = [];
let waypointId;

$('.waypoint').each((idx, ele) => {
  const scene = new ScrollMagic.Scene()
    .triggerElement(ele)
    .setClassToggle(ele, 'waypoint--active')
    .triggerHook(0.9)
    .on('enter', () => {
      waypointId = revisedRandId();
      uniqueID.push(waypointId);
      $(ele).data('waypointId', waypointId).trigger('waypoint.on');
    });

  // create a standard scene and add it to a controller
  scene.addTo(controller);
});

// Check waypoint ID against global waypoint ID array
const waypointElemActive = ele => uniqueID.indexOf($(ele).parents('.waypoint').data('waypointId')) !== -1;

export default waypointElemActive;
