/* Purpose: To help add classes to an element that cannot be done so using css animations */
const removeAnimation = (element, animationName) => {
  element.classList.remove(animationName);
};

const init = (element, animationName, preAnimateFunc, postAnimateFunc) => {
  if (preAnimateFunc) preAnimateFunc(element, animationName);
  element.classList.add(animationName);
  const handleAnimationEnd = () => {
    element.removeEventListener('animationend', handleAnimationEnd);
    if (postAnimateFunc) postAnimateFunc(element, animationName);
  };
  element.addEventListener('animationend', handleAnimationEnd);
};

export { init as addAnimation, removeAnimation };
