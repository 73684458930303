/* global appConfig, Foundation, ga, jQuery */

import 'foundation-sites/js/foundation.abide';
import Cookies from 'js-cookie';
import AnalyticsHelper from '../../assets/js/helpers/analytics/analytics-helper';
import CSRFHelper from '../../assets/js/helpers/csrf-helper';
import { uploadFormHelper, getUploadFileUrls } from '../../assets/js/helpers/upload-form-helper';

/**
 * This is a function used to generate a callback which is bound to the window when a form is initialised
 * @param  {function} originalFormSubmitSuccess Currently bound window.formSubmitSuccess
 * @param  {function} originalFormSubmitError   Currently bound window.formSubmitError
 * @return {function}                           Callback called with success response (JSON) by the data
 *                                              layer when a form submission succeeds.
 *                                              Shows success message and resets global variables.
 */
const generateDataLayerFormSubmitSuccess = (originalFormSubmitSuccess, originalFormSubmitError, debugMode, displayFormError) => (
  output => {
    if (debugMode) {
      // Parse output for errors only when in debug mode so as not to risk
      // breaking for end users due a malformed payload
      const { has_errors: hasErrors, api_status: apiResults, form_id: formId } = JSON.parse(output);
      let errorMessage;
      let errorDetail;
      if (hasErrors) {
        errorMessage = `This form is running in debug mode and has received an error, please see Seer crew for more details. \nForm ID: ${formId}`;
        // Get active endpoints
        const activeEndpoints = Object.entries(apiResults).filter(
          ([_, { active }]) => active
        );
        if (activeEndpoints.length === 0) {
          errorMessage += ' (this form ID has not been configured)';
        } else {
          errorDetail = '';
          errorMessage += '\n';
          // Collate errors for each active endpoint that failed
          activeEndpoints.filter(
            ([_, { success }]) => !success
          ).forEach(([endpoint, { message }]) => {
            errorDetail += `\n${endpoint}: ${message}`;
          });
        }
      }
      if (errorMessage && errorMessage.length > 0) {
        displayFormError({ errorMessage }, errorDetail);
        return;
      }
    }
    const { form_id: formId } = JSON.parse(output);
    const formElem = document.getElementById(formId);
    const formRedirectUrl = formElem.getAttribute('data-myob-form-redirect-url');
    if (formRedirectUrl) {
      window.location.href = formRedirectUrl;
    } else if (formElem) {
      // Show success
      const successElem = formElem.querySelector('.form__success-response');
      if (successElem) {
        successElem.classList.remove('no-display');
        successElem.classList.add('anim--fade-in');
      }
      // Hide body
      const bodyElem = formElem.querySelector('.form__body');
      if (bodyElem) {
        bodyElem.classList.add('no-display');
      }
    }
    // Reset global vars to original values
    window.formSubmitSuccess = originalFormSubmitSuccess;
    window.formSubmitError = originalFormSubmitError;
  }
);

(function myobFormFactory($, Foundation) {
  class MyobForm extends Foundation.Abide {
    constructor(element, options) {
      const overrideOptions = $.extend(options, MyobForm.defaults);
      super(element, overrideOptions);
      this.crsfHelper = new CSRFHelper();

      Foundation.registerPlugin(this, 'MyobForm');
    }

    _init() {
      this.analyticsHelper = new AnalyticsHelper();

      this.$formId = this.$element[0].id || '';
      this.$formBody = this.$element.find('.form__body');
      this.$formSuccess = this.$element.find('.form__success-response');
      this.$formError = this.$element.find('.form__error-response');
      this.$submitButton = this.$element.find('[type=submit]');
      this.$formCookieName = 'form-submission-id';
      this.$formCookieValue = this.$formId;
      this.$formSubmissionType = this.$element.attr('data-myob-form-submission-type');
      this.$formReturnSelf = this.$element.attr('data-myob-form-return-self');
      this.$formDebug = !!(this.$element.attr('data-myob-form-debug') !== undefined && this.$element.attr('data-myob-form-debug') !== false);
      this.$fileUploadSuccess = this.$element.find('.file-inline-upload-success');
      this.$element.data('height', `${this.$formSuccess.outerHeight()}`);
      this.$isProgressiveForm = this.$element.attr('data-myob-form-progressive');
      this.$progressiveFormCookieName = 'progressive-form';
      this.$currentProgressiveFormDataAttrName = 'data-myob-form-progressive-current';
      this.$hasError = window.Site.getUrlParameter('errors') === 'true';
      this.$hubspotTrackingCookieName = 'hubspotutk';

      /* Check if datalayer is active and display info alert */

      const adblockAlert = () => {
        // Default Form Alert
        const adblockAlertElement = document.createElement('div');
        adblockAlertElement.innerHTML = `
          <section class="alert alert--info alert--adblock" data-alert-id="alert-info" data-myob-alert>
            <div class="alert__body">
              <div class="alert__icon-area">
                <svg aria-hidden="true" role="img" class="icon icon--info icon--sm">
                  <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/etc/designs/myob/icons/global.map.svg#info"></use>
                </svg>
              </div>
              <div class="alert__copy">
                <p>
                  <b>Oops - Looks like your browser has an ad blocker</b><br/>
                  To submit the form, temporarily disable the ad blocker and refresh the page.
                </p>
              </div>
            </div>
          </section>
        `;

        this.$element.find('input, textarea, select').on('focus', () => {
          if (window?.dataLayer.find(item => item.event === 'gtm.js') === undefined) {
            if (this.$formBody.find('.alert--adblock').length === 0) {
              this.$formBody.prepend(adblockAlertElement);
              this.$submitButton.addClass('is-disabled');
            }
          }
        });
      };

      adblockAlert();

      /* END Check if datalayer is active and display info alert */

      if (this.$fileUploadSuccess) {
        uploadFormHelper(this.$submitButton.get(0), this.$fileUploadSuccess.get(0));
      }

      if (this.$isProgressiveForm && !window.Site.formSuccessId && !this.$hasError) {
        this.fetchProspectDetails()
          .then(response => this.displayProgressiveForm(response))
          .catch(() => {
            // Display the first form as fallback
            this.$element.find(`[${this.$currentProgressiveFormDataAttrName}='1']`)
              .removeClass('no-display').addClass('anim--fade-in');

            // Set a cookie to fetch the correct success/failure message to display on submission
            Cookies.set(this.$progressiveFormCookieName, { currentForm: '1' });

            this.deleteHiddenProgressiveForms();
          });
      }
      super._init();
    }

    static customTrim(s) {
      return s.trim().replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    }

    static onInputFocus(ev) {
      $(ev.target.parentNode).addClass('form__group--filled');
    }

    static onInputBlur(ev) {
      if (MyobForm.customTrim(ev.target.value) === '' && !$(ev.target).hasClass('is-invalid')) {
        $(ev.target.parentNode).removeClass('form__group--filled');
      }
    }

    static onSelectBlur(ev) {
      if (ev.target.options[ev.target.selectedIndex].text === '' && !$(ev.target).hasClass('is-invalid')) {
        $(ev.target.parentNode).removeClass('form__group--filled');
      }
    }

    static minRequiredValidator($el) {
      const $parentEl = $el.closest('[data-required-min]');
      if (!$parentEl.attr('required')) return true;

      const min = $parentEl.attr('data-required-min');
      const total = $parentEl.find(':input:checked').length;
      const $inputElement = $parentEl.find(':input');

      if (min > total) {
        $parentEl.find(MyobForm.defaults.formErrorSelector).show();
        $inputElement.each((index, checkbox) => {
          $(checkbox)
            .addClass('is-invalid')
            .attr('data-invalid');
          $(checkbox)
            .parent()
            .addClass('is-invalid');
        });
        return false;
      }

      $inputElement.each((index, checkbox) => {
        $(checkbox)
          .removeClass('is-invalid')
          .removeAttr('data-invalid');
        $(checkbox)
          .parent()
          .removeClass('is-invalid');
      });

      $parentEl.find(MyobForm.defaults.formErrorSelector).hide();

      return true;
    }

    static convertRequestToJSON(data) {
      const unindexedArray = data;
      const indexedArray = {};

      $.map(unindexedArray, n => {
        indexedArray[n.name] = n.value;
      });

      indexedArray.pageUrl = window.location.href;
      indexedArray.pageTitle = document.title;
      if (getUploadFileUrls() && getUploadFileUrls().length > 0) {
        indexedArray['upload-file-urls'] = getUploadFileUrls();
      }
      return { data: indexedArray };
    }

    deleteHiddenProgressiveForms() {
      this.$element.find(`div[${this.$currentProgressiveFormDataAttrName}]`).each((index, el) => {
        if ($(el).hasClass('no-display')) {
          $(el).find('input, select').prop('required', false);
          $(el).remove();
        }
      });
    }

    /**
     *  Display the correct progressive form variation based on the response returned from Pardot for a particular prospect
     */
    displayProgressiveForm(response) {
      const prospectData = {
        company: response.fieldList.company,
        first_name: response.fieldList.first_name,
        industry: response.fieldList.industry,
        last_name: response.fieldList.last_name,
        phone_number: response.fieldList.phone,
        MYOB_customer: response.fieldList.MYOB_customer
      };

      // Fallback to the last form if all prospect info have already been collected
      let formsAvailableToDisplay = ['last'];

      Object.entries(prospectData).forEach(([key, value]) => {
        if (!value) { // Prospect data not captured
          // Find form in which this field exists
          $('.form .inputcomp input, select').each((index, el) => {
            if ($(el).attr('name') === key && $(el).attr('required')) {
              formsAvailableToDisplay.push($(el)
                .closest(`div[${this.$currentProgressiveFormDataAttrName}]`)
                .attr(this.$currentProgressiveFormDataAttrName));
            }
          });
        }
      });

      // Sort in ascending order to figure out the right form to display
      formsAvailableToDisplay = formsAvailableToDisplay.sort();

      const currentForm = this.$element.find(`[${this.$currentProgressiveFormDataAttrName}=${formsAvailableToDisplay[0]}]`);

      // Set a cookie to fetch the correct success/failure message to display on submission
      Cookies.set(this.$progressiveFormCookieName, { currentForm: formsAvailableToDisplay[0], firstName: response.first_name });

      currentForm.removeClass('no-display').addClass('anim--fade-in');

      const currentFormHeading = currentForm.find('h2.form-title');
      // Replace any variation of the {FIRST_NAME} template with the prospect's actual first name
      currentFormHeading.text(currentFormHeading.text().replace(/{?FIRST[\W|_]?NAME}?/gi, response.first_name));

      this.deleteHiddenProgressiveForms();

      // Phone number has already been captured in a previous form - delete the field
      if (prospectData.phone_number) {
        currentForm.find('input[id^="phone_number_"]').removeAttr('required');
        currentForm.find('.phone-number, .phone-number-required').remove();
      }
    }

    /**
     * This is a function that is used to retrieve the prospect details from Pardot when using a progressive profiling form
     */
    fetchProspectDetails() {
      $(document)
        .ajaxStart(() => this.$element.prepend('<div class="progress-indicator block m-auto"></div>'))
        .ajaxComplete(() => this.$element.find('.progress-indicator').hide());

      return new Promise((resolve, reject) => {
        const pardotCookieName = 'visitor_id502331';
        const { pardot } = appConfig;

        $.ajax({
          type: 'POST',
          url: `${pardot.baseUrl}${pardot.prospectEndpoint}`,
          headers: {
            Accept: 'application/json; charset=utf-8',
          },
          data: JSON.stringify({
            visitor_id: Cookies.get(pardotCookieName)
          })
        })
          .done(response => {
            if (!response) return reject(response);

            return resolve(response);
          })
          .fail(error => reject(error));
      });
    }

    /**
     * Submit synchronously
     */
    submit(formId) {
      // Create cookie with form id for pardot form redirection
      Cookies.set(this.$formCookieName, this.$formCookieValue);
      const formEl = document.getElementById(formId);
      // Update cookie with first name value from the form data for Form 1
      if (this.$isProgressiveForm) {
        const progressiveFormCookie = JSON.parse(Cookies.get(this.$progressiveFormCookieName));
        if (!progressiveFormCookie.firstName) {
          Cookies.set(this.$progressiveFormCookieName, { ...progressiveFormCookie, firstName: this.$element.find('#first_name').val() });
        }
      }

      // Make sure to remove search parameters in the redirect url.
      const loc = window.location.origin + window.location.pathname;
      formEl.action = `${formEl.action}?error_location=${encodeURIComponent(loc)}`;
      if (this.$formReturnSelf === 'true') {
        formEl.action += `&success_location=${encodeURIComponent(loc)}`;
      }

      formEl.submit();
    }

    populateGAFields() {
      if (typeof ga !== 'undefined' && typeof ga === 'function') {
        let GATracker;
        let GATrackingId;
        let GAClientId;

        ga(() => {
          [GATracker] = ga.getAll();
          GATrackingId = GATracker.get('trackingId');
          GAClientId = GATracker.get('clientId');
        });

        this.$element.find('#GATRACKID').val(GATrackingId);
        this.$element.find('#GACLIENTID').val(GAClientId);
      }
    }

    /**
     * Callback which is bound to a callback on the window, triggered when a form errors.
     * This displays error view within.
     * It does not reset the formSubmitSuccess/formSubmitError functions bound to the window
     * as the user may wish to retry to submit the form.
     */
    dataLayerFormSubmitError = () => {
      // Show error
      this.$formError.removeClass('no-display').addClass('anim--fade-in');

      // Re-enable submit
      this.$submitButton.removeClass('is-disabled');
      this.$submitButton.removeClass('is-loading');
      this.$submitButton.removeAttr('disabled');
    };

    /**
     * Submit asynchronously
     */
    ajaxPost(formId, token) {
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: this.$element.attr('action'),
          headers: {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8',
          },
          data: JSON.stringify(MyobForm.convertRequestToJSON(this.$element.serializeArray())),
          beforeSend: xhr => {
            if (token) {
              xhr.setRequestHeader('CSRF-Token', token);
            }
          },
        })
          .done(response => {
            if (response.status === 1) {
              this.analyticsHelper.record('AEM:form:formSuccess', {
                eventInfo: {
                  eventName: 'genericFormSuccess',
                },
                attributes: {
                  _sourceElement: this.$element,
                  formId,
                },
              });
              resolve(response);
            } else {
              this.analyticsHelper.record('AEM:form:formError', {
                eventInfo: {
                  eventName: 'genericFormFail',
                },
                attributes: {
                  _sourceElement: this.$element,
                  formId,
                  errorMessage: this.$formError.text().trim(),
                },
              });
              reject(response);
            }
          })
          .fail(response => {
            this.analyticsHelper.record('AEM:form:formError', {
              eventInfo: {
                eventName: 'genericFormFail',
              },
              attributes: {
                _sourceElement: this.$element,
                formId,
                errorMessage: this.$formError.text().trim(),
              },
            });

            reject(response);
          });
      });
    }

    displayFormError = (errorResponse = {}, errorDetail = null) => {
      if (errorResponse) {
        if (errorResponse.errorMessage) {
          this.$formError.text(errorResponse.errorMessage);
        }
        if (errorDetail) {
          this.$formError.append('<code />');
          this.$formError.find('code').text(errorDetail);
        }
      }

      this.$submitButton.removeClass('is-loading').addClass('is-unsuccessful');
      this.$formError.removeClass('no-display').addClass('anim--fade-in');

      setTimeout(() => {
        this.$submitButton.removeClass('is-disabled is-unsuccessful').attr('disabled', false);
      }, 2000);
    };

    _events() {
      super._events();
      const analyticsFormId = AnalyticsHelper.refineFormId(this.$formId);

      let currentForm = this.$element;
      let currentFormBody = this.$formBody;
      let currentFormSuccess = this.$formSuccess;
      let currentFormError = this.$formError;

      // Show the success message
      if (window.Site.formSuccessId === this.$formId) {
        if (this.$isProgressiveForm) {
          const progressiveFormCookie = JSON.parse(Cookies.get(this.$progressiveFormCookieName));
          currentForm = this.$element.find(`[${this.$currentProgressiveFormDataAttrName}=${progressiveFormCookie.currentForm}]`);

          // Replace any variation of the {FIRST_NAME} template with the prospect's actual first name
          currentFormSuccess = currentForm.find(this.$formSuccess);
          const currentFormSuccessHeading = currentFormSuccess.find('h2 span');
          currentFormSuccessHeading.text(currentFormSuccessHeading.text().replace(/{?FIRST[\W|_]?NAME}?/gi, progressiveFormCookie.firstName || ''));

          currentForm.removeClass('no-display');
          currentFormBody = currentForm.find(this.$formBody);
        }

        currentFormBody.addClass('no-display');
        currentFormSuccess.removeClass('no-display').addClass('anim--fade-in');

        window.addEventListener('resize', () => {
          const DOMChange = window.Site.debounce(() => {
            this.$element.animate({ height: currentFormSuccess.outerHeight() }, 250);
          }, 250);

          DOMChange();
        });
      }

      // Remove the "errors" param in url if cookie doesn't exist (2nd visit to error url)
      if (this.$hasError) {
        if (!Cookies.get(this.$formCookieName)) {
          window.location.href = window.location.origin + window.location.pathname;
        } else {
          if (this.$isProgressiveForm) {
            const progressiveFormCookie = JSON.parse(Cookies.get(this.$progressiveFormCookieName));
            currentForm = this.$element.find(`[${this.$currentProgressiveFormDataAttrName}=${progressiveFormCookie.currentForm}]`);
            currentFormError = currentForm.find(this.$formError);

            currentForm.removeClass('no-display');
            currentFormBody = currentForm.find(this.$formBody);
          }
          currentFormBody.addClass('no-display');
          currentFormError.removeClass('no-display').addClass('anim--fade-in');

          Cookies.remove(this.$formCookieName);
        }
      }

      const formId = this.$formId;
      let formStartFlag = false;
      this.$inputs.each((index, inputEl) => {
        $(inputEl).on('focus', () => {
          if (!formStartFlag) {
            this.analyticsHelper.record('AEM:form:formStart', {
              eventInfo: {
                eventName: 'genericFormStart',
              },
              attributes: {
                formId: analyticsFormId,
              },
            });

            if (this.$formSubmissionType === 'SYNCHRONOUS') {
              this.populateGAFields();
            }

            formStartFlag = true;
          }
        });

        if (MyobForm.customTrim(inputEl.value) !== '') {
          $(inputEl)
            .not('input[type="hidden"]')
            .addClass('form__group--filled');
        }

        if ($(inputEl).is('select')) {
          if (inputEl.options[inputEl.options.selectedIndex].text !== '') {
            $(inputEl)
              .parent()
              .addClass('form__group--filled');
          } else {
            inputEl.addEventListener('blur', MyobForm.onSelectBlur);
          }
        } else {
          inputEl.addEventListener('blur', MyobForm.onInputBlur);
        }

        inputEl.addEventListener('focus', MyobForm.onInputFocus);
      });

      this.$element.on('forminvalid.zf.abide', () => {
        this.$submitButton.addClass('is-disabled is-unsuccessful').attr('disabled', true);

        setTimeout(() => {
          this.$submitButton.removeClass('is-disabled is-unsuccessful').attr('disabled', false);
        }, 2000);
      });

      this.$element.on('submit.zf.abide', event => {
        event.preventDefault();

        this.analyticsHelper.record('AEM:form:formSubmit', {
          eventInfo: {
            eventName: 'genericFormSubmit',
          },
          attributes: {
            formId: analyticsFormId,
          },
        });

        if (this.validateForm()) {
          // Get hubspot tracking cookie value
          this.$hubspotTrackingCookieValue = Cookies.get(this.$hubspotTrackingCookieName);
          this.$submitButton.addClass('is-disabled is-loading').attr('disabled', true);
          this.$formError.addClass('no-display');

          // Set hidden email field to send out external email notifications
          const emailField = this.$element.find('input[type=email]');
          if (emailField) this.$element.find('#hiddenEmail').val(emailField.val());

          this.crsfHelper.ajaxGetToken(this.$element.attr('action')).then(token => {
            if (this.$formSubmissionType === 'ASYNCHRONOUS_SFMC') {
              window.formSubmitSuccess = generateDataLayerFormSubmitSuccess(
                window.formSubmitSuccess,
                window.formSubmitError,
                this.$formDebug,
                this.displayFormError
              );
              window.formSubmitError = this.dataLayerFormSubmitError;
              this.$formData = MyobForm.convertRequestToJSON(this.$element.serializeArray()).data;
              if (this.$hubspotTrackingCookieValue) {
                this.$formData.hutk = this.$hubspotTrackingCookieValue;
              }
              this.analyticsHelper.pushData.pushToGoogle({
                elementId: analyticsFormId,
                event: 'Form Submit',
                formData: this.$formData,
              });
            } else if (this.$formSubmissionType !== 'SYNCHRONOUS') {
              this.ajaxPost(analyticsFormId, token).then(
                value => {
                  this.$submitButton.removeClass('is-loading is-disabled').addClass('is-successful').attr('disabled', false);

                  setTimeout(() => {
                    this.$submitButton.removeClass('is-successful');

                    if (value.successAction && value.successAction === 'goToUrl' && value.targetURL) {
                      window.location = value.targetURL;
                    } else {
                      this.$element.animate({ height: this.$element.data('height') }, 250);
                      this.$formBody.addClass('no-display');
                      this.$formSuccess.removeClass('no-display').addClass('anim--fade-in');
                    }
                  }, 2000);
                },
                this.displayFormError
              );
            } else {
              this.submit(formId);
            }
          });
        }
      });
    }
  }

  MyobForm.defaults = {
    liveValidate: false,
    validateOnBlur: true,
    labelErrorClass: 'is-invalid',
    inputErrorClass: 'is-invalid',
    formErrorSelector: '.form__error-message',
    formErrorClass: 'is-visible',
    componentClass: 'form',
    debug: false,
    validators: {
      min_required: MyobForm.minRequiredValidator,
    },
  };

  Foundation.plugin(MyobForm, 'MyobForm');
}(jQuery, Foundation));
