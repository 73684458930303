/* global Foundation, jQuery */

import 'jquery-lazyload';

(function myobImageFactory($, window, Foundation) {
  class MyobImage {
    constructor(element, options) {
      this.$element = element;
      this.options = $.extend({}, MyobImage.defaults, this.$element.data(), options);

      this.outerDims = {
        width: $(window).width(),
      };
      this._init();
      Foundation.registerPlugin(this, 'MyobImage');
    }

    _init() {
      if (typeof this.options.interchange === 'string') {
        this.setImage(this.$element, this.options.interchange);
        this._events();
      }
    }

    _events() {
      // Reevaluate your image choices after resizing the window
      window.addEventListener('resize', () => {
        const DOMChange = window.Site.debounce(() => {
          this.outerDims.width = $(window).width();
          this.setImage(this.$element, this.options.interchange);
        }, 250);

        DOMChange();
      });
    }

    getImage(options) {
      const regularChar = new RegExp('\'', 'g');
      let overrideOptions = options.replace(regularChar, '');
      overrideOptions = overrideOptions.substring(1, overrideOptions.length - 1);
      overrideOptions = overrideOptions.split(',');
      let [imgSrc] = overrideOptions;

      if (overrideOptions !== null && overrideOptions.length > 0) {
        // If there is no mobile rendition fall back on desktop rendition
        if (overrideOptions[1] != null && this.outerDims.width < window.Site.minSmallScreen) {
          [, imgSrc] = overrideOptions; // Mobile rendition
        }
      }

      return imgSrc;
    }

    setImage(element, options) {
      const imgSrc = this.getImage(options);
      const smallScreen = this.outerDims.width < window.Site.minSmallScreen;
      if (element.is('img')) {
        const srcNow = element.attr('src');

        if (srcNow !== imgSrc) {
          element.attr('data-original', imgSrc);
          element.lazyload({
            threshold: 50,
          });
        }
      } else if (smallScreen && element.hasClass('mobile-overlay')) {
        const backgroundImg = `linear-gradient(rgba(243,238,246,.8), rgba(243,238,246,.8)), url(${imgSrc})`;
        element.css('background-image', backgroundImg);
      } else if (smallScreen && element.hasClass('mobile-replace')) {
        const backgroundImg = `url(${imgSrc})`;
        element.css('background-image', backgroundImg);
      } else {
        element.attr('data-original', imgSrc);
        element.lazyload({
          threshold: 50
        });
      }
    }
  }

  Foundation.plugin(MyobImage, 'MyobImage');
}(jQuery, window, Foundation));
