import {
  setItemState, isHidden, addActiveState, removeActiveState,
} from '../../assets/js/helpers/state-helper';

const show = (item, animIn, animOut) => {
  if (isHidden(item)) setItemState(item, animIn, animOut, 'show');
};

const hide = (item, animIn, animOut) => {
  if (!isHidden(item)) setItemState(item, animIn, animOut, 'hide');
};

const makeToggleInactive = ({
  toggle, items, animIn, animOut
}) => {
  removeActiveState(toggle);
  toggle.setAttribute('aria-expanded', 'false');
  items.forEach(item => hide(item, animIn, animOut));
};

const makeToggleActive = ({
  toggle, items, animIn, animOut
}) => {
  addActiveState(toggle);
  toggle.setAttribute('aria-expanded', 'true');
  items.forEach(item => show(item, animIn, animOut));
};

export {
  hide, show, makeToggleActive, makeToggleInactive,
};
