/* Purpose: To help add classes to an element that cannot be done so using css transitions */

/**
 * Removes the given transition class from the given element
 * @param {HTMLElement} element     The element to remove the transition from
 * @param {string} transitionClass  The transition class to remove from the given element
 */
const removeTransition = (element, transitionClass) => {
  element.classList.remove(transitionClass);
};

/**
 * Triggers a transition on the given element (by calling startTransition)
 * and then on transitionend, cleans up using cleanUpTransition
 * @param  {HTMLElement} element           The element the transition will run on
 * @param  {Function} startTransition   Function to trigger the transition
 * @param  {Function} cleanUpTransition Clean up after the transition has finished
 * @return {Function}
 */
const runTransitionOnElement = (element, startTransition, cleanUpTransition) => {
  if (cleanUpTransition) {
    const handleTransitionEnd = () => {
      cleanUpTransition(element);
      element.removeEventListener('transitionend', handleTransitionEnd);
    };
    element.addEventListener('transitionend', handleTransitionEnd);
  }
  if (startTransition) startTransition(element);
};

/**
 * Sets up the transiton event listeners, while running the modifier functions
 * @param {HTMLElement} element       The element the transition will run on
 * @param {String} transitionClass    The css class that has the transition styles
 * @param {Function} setupTransition     Runs the given function before the transition starts
 * @param {Function} startTransition   Runs the given function which should trigger the transition
 * @param {Function} cleanUpTransition    Runs the given function once the transition has ended
 */
const addTransition = (element, transitionClass, setupTransition, startTransition, cleanUpTransition) => {
  if (setupTransition) setupTransition(element);
  element.classList.add(transitionClass);

  /*
    This retry strategy is required to ensure the class added
    just above is present on the element before the transition is triggered.
    I.e. add a transition to the element that listens to height using the transitionClass
         then change the height on the element manually using startTransition to trigger
         that added height transition
    */
  const retryStartTransition = setInterval(
    () => {
      if (element.classList.contains(transitionClass)) {
        runTransitionOnElement(element, startTransition, cleanUpTransition);
        clearInterval(retryStartTransition);
      }
    },
    10
  );

  setTimeout(() => {
    clearInterval(retryStartTransition);
  }, 100);
};

export { addTransition, removeTransition };
