/* global Foundation, jQuery, Site */

import 'foundation-sites/js/foundation.reveal';
import 'foundation-sites/js/foundation.util.keyboard';
import 'foundation-sites/js/foundation.util.box';
import 'foundation-sites/js/foundation.util.triggers';
import 'foundation-sites/js/foundation.util.motion';
import AnalyticsHelper from '../../assets/js/helpers/analytics/analytics-helper';

(function myobModalFactory($, Foundation) {
  class MyobModal extends Foundation.Reveal {
    constructor(element, options) {
      const overrideOptions = $.extend({}, MyobModal.defaults, element.data(), options);

      super(element, overrideOptions);

      Foundation.registerPlugin(this, 'MyobModal');
    }

    _init() {
      this.analyticsHelper = new AnalyticsHelper();

      const elementId = this.$element[0].id || '';

      if (elementId) {
        let openModal = false;
        if (Site.getUrlParameter('open-modal') === elementId) {
          openModal = true;
        }
        if (openModal) {
          $(window).one('load.zf.reveal', this.open.bind(this));
        }
      }
      super._init();
    }

    _events() {
      super._events();

      const that = this;
      const modalId = this.$element[0].id || '';
      let modalName = this.$element.find('.modal__title').text() || '';
      if (!modalName) {
        modalName = modalId;
      }

      this.$element.on('closed.zf.reveal', ele => {
        that.analyticsHelper.record('AEM:modal:modalClosed', {
          eventInfo: {
            eventName: 'modalClosed',
          },
          attributes: {
            componentClass: 'modal',
            _sourceElement: ele.target,
            modalId,
            modalName,
          },
        });
      });

      this.$element.on('open.zf.reveal', ele => {
        that.analyticsHelper.record('AEM:modal:modalOpened', {
          eventInfo: {
            eventName: 'modalOpened',
          },
          attributes: {
            componentClass: 'modal',
            _sourceElement: ele.target,
            modalId,
            modalName,
          },
        });
      });
    }
  }

  Foundation.plugin(MyobModal, 'MyobModal');
}(jQuery, Foundation));
