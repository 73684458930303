import Url from 'url-parse';
import queryString from 'qs';
import { registerPluginNames } from '../../assets/js/system/registrar';

const defaults = {
  smartParams: '',
  smartHash: null,
};

let urlQueryStringParams = null;
let urlHash = null;

const constructElementDataSet = element => ({
  smartParams: element.dataset.smartParams || '',
  smartHash: element.dataset.smartHash === 'true' ? element.dataset.smartHash : null,
});

const getDefaults = elemDataSet => {
  const newDataSet = {};
  const originalDataSet = { ...defaults };

  Object.keys(elemDataSet).forEach(key => {
    if (elemDataSet[key] !== null) {
      newDataSet[key] = elemDataSet[key];
    }
  });

  return Object.assign(originalDataSet, newDataSet);
};

const buildNewButtonQuery = (newHrefQueryParams, requestedQueryParams) => {
  const buttonQuery = [];
  const cleanedRequestedQueryParams = [];
  const finalHrefQueryParams = newHrefQueryParams || {};

  // Clean up whitespace around requested params
  for (let i = 0; i < requestedQueryParams.length; i++) { // eslint-disable-line no-plusplus
    cleanedRequestedQueryParams.push(requestedQueryParams[i].trim());
  }

  Object.keys(urlQueryStringParams).forEach(item => {
    if (cleanedRequestedQueryParams.indexOf(item) > -1) {
      finalHrefQueryParams[item] = urlQueryStringParams[item];
    }
  });

  Object.keys(finalHrefQueryParams).forEach(item => {
    buttonQuery.push(`${item}=${finalHrefQueryParams[item]}`);
  });

  return buttonQuery;
};

const updateButtonHref = elementData => {
  if (!urlQueryStringParams && !urlHash) {
    // Nothing to do, so exit early
    return;
  }

  Array.from(elementData).forEach(element => {
    if (!(element.defaults.smartParams || element.defaults.smartHash)) {
      // Nothing to do, so exit early
      return;
    }

    const buttonUrl = new Url(element.target.getAttribute('href'));
    let buttonQuery = [];
    let updateButtonUrl = false;

    if (buttonUrl.query.length > 0 || element.defaults.smartParams) {
      buttonQuery = buildNewButtonQuery(queryString.parse(buttonUrl.query.substr(1)), element.defaults.smartParams.split(','));
    }

    if (buttonQuery.length > 0) {
      buttonUrl.set('query', `?${buttonQuery.join('&')}`);
      updateButtonUrl = true;
    }

    if (element.defaults.smartHash === 'true' && urlHash) {
      buttonUrl.set('hash', `#${urlHash}`);
      updateButtonUrl = true;
    }

    if (updateButtonUrl) {
      element.target.setAttribute('href', buttonUrl.toString());
    }
  });
};

const setSourceParametersAndHash = () => {
  const urlQueryWithoutQuestionMark = window.location.search.substr(1);
  const urlHashWithoutHash = window.location.hash.substr(1);

  if (!(urlQueryWithoutQuestionMark || urlHashWithoutHash)) {
    // Nothing to do, so bail early
    return;
  }

  // Updating module variable
  urlQueryStringParams = queryString.parse(urlQueryWithoutQuestionMark);
  urlHash = urlHashWithoutHash;
};

const init = elements => {
  const elementData = Array.from(elements).map(element => ({
    target: element,
    defaults: getDefaults(constructElementDataSet(element))
  }));

  setSourceParametersAndHash();
  updateButtonHref(elementData);
};

export default init;

registerPluginNames(init, 'smart-call-to-action-button');
