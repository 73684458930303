/* global $ */

import register from './registrar';

export default () => {
  if (window.Foundation) {
    $(document).foundation();
  }

  if (document.readyState !== 'loading') {
    register();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      register();
    });
  }
};
