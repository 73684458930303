import 'foundation-sites/js/foundation.core';
import 'foundation-sites/js/foundation.util.mediaQuery';
import 'foundation-sites/js/foundation.equalizer';
import 'foundation-sites/js/foundation.util.timerAndImageLoader';

/* Form */
import '../../../components/form/form.vanilla';

/* General */
import '../../../components/accordion/accordion.vanilla';
import '../../../components/accordion-menu/accordion-menu.vanilla';
import '../../../components/alert/alert.vanilla';
import '../../../components/browser-upgrade/browser-upgrade.vanilla';
import '../../../components/card/card.vanilla';
import '../../../components/card/card.animation';
import '../../../components/dropdown-menu/dropdown-menu.vanilla';
import '../../../components/tabs/tabs.vanilla';
import '../../../components/typed-text/typed-text.vanilla';

/* Layout */
import '../../../components/modal/modal.vanilla';
import '../../../components/footer/footer.vanilla';

/* Media */
import '../../../components/image/image.vanilla';
import '../../../components/video/video-wistia.vanilla';
import '../../../components/video/video-youtube.vanilla';

/* Navigation */
import '../../../components/navigation-payments/navigation-payments.vanilla';
import '../../../components/sub-navigation/sub-navigation.vanilla';

/* Product Displays */
import '../../../components/pricing-calculator/pricing-calculator.vanilla';
