export const getSearchFromURL = () => (window.location ? window.location.search : '');

export const updateSearchInURL = newQuerystring => {
  window.history.replaceState({}, document.title, newQuerystring || window.location.pathname);
};

export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const XLARGE = 'xlarge';
export const XXLARGE = 'xxlarge';

/*
  These are the max widths for each viewport.
  Keep these in sync with src/assets/css/settings/_variables.scss
  */
export const breakpoints = {
  [SMALL]: 575,
  [MEDIUM]: 767,
  [LARGE]: 991,
  [XLARGE]: 1199,
  [XXLARGE]: Infinity,
};

export const getInnerWidth = () => window.innerWidth;

/**
 * Get viewport of current window
 * @return {String} One of SMALL, MEDIUM, LARGE, XLARGE, XXLARGE
 */
export const getViewport = () => {
  const innerWidth = getInnerWidth();
  if (innerWidth < breakpoints[SMALL]) return SMALL;
  if (innerWidth < breakpoints[MEDIUM]) return MEDIUM;
  if (innerWidth < breakpoints[LARGE]) return LARGE;
  if (innerWidth < breakpoints[XLARGE]) return XLARGE;
  return XXLARGE;
};

/**
 * Check if current window is less than the given viewport
 * @param  {String}  viewport One of SMALL, MEDIUM, LARGE, XLARGE, XXLARGE
 * @return {Boolean}          True if it is less than the given viewport's max width
 */
export const isLessThan = viewport => {
  const innerWidth = getInnerWidth();
  if (viewport in breakpoints) {
    return innerWidth < breakpoints[viewport];
  }
  return null;
};

/**
 * Checks if the given rectangle fits into viewport
 * @return {Boolean}        True if it fits, else false
 */
export const isInViewport = ({
  top, left, right, bottom
}) => (
  top >= 0 && left >= 0
  && bottom <= (window.innerHeight || document.documentElement.clientHeight)
  && right <= (window.innerWidth || document.documentElement.clientWidth)
);

/**
 * Checks if the given rectangle fits into the scrollable viewport
 * @return {Boolean}        True if it fits, else false
 */
export const isInScrollingViewport = ({
  top, left, right, bottom
}) => (
  -top <= document.documentElement.scrollTop
  && -left <= document.documentElement.scrollLeft
  && bottom <= document.documentElement.scrollHeight
  && right <= document.documentElement.scrollWidth
);

/**
 * Check if current window is less than the given viewport
 * @param  {String}  viewport One of SMALL, MEDIUM, LARGE, XLARGE, XXLARGE
 * @param  {Boolean} equalTo  True if the comparison should be >=, false for >
 * @return {Boolean || null}  True if it is less than (or possibly equal to) the given viewport's max width.
 *                            Null if given viewport does not match our list of recognised viewports.
 */
export const isGreaterThan = (viewport, equalTo = false) => {
  const innerWidth = getInnerWidth();

  if (viewport in breakpoints) {
    let flag = false;
    if (equalTo === true) {
      flag = innerWidth >= breakpoints[viewport];
    } else {
      flag = innerWidth > breakpoints[viewport];
    }
    return flag;
  }
  return null;
};
