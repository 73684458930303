const ComparisonTableReaction = () => {
  const CONSTANTS = {
    STICKY_TIER_GROUP: 'comparison-table__tier-group--sticky',
    NONE_STICK_CHECKOUT: 'comparison-table__sticky-checkout--none-stick',
    SHOW_STICKY_CHECKOUT: 'comparison-table__sticky-checkout--show',
    IS_SELECTED: 'is-selected',
    PROMOTE_BANNER_CLASS: '.comparison-table__promote-banner',
    TIER_CLASS: '.comparison-table__tier--au',
    SPEC_CLASS: '.comparison-table__spec--au',
    ROW_CLASS: '.comparison-table__row',
    PRODUCT_NAME_CLASS: '.comparison-table__product-name',
    DATA_COMPARISON_TABLE: '[data-comparison-table]',
    DATA_TIER_GROUP: '[data-tier-group]',
    DATA_EXTRA_INFO: '[data-extra-info]',
    DATA_STICKY_CHECKOUT: '[data-sticky-checkout]',
    DATA_STICKY_CHECKOUT_DESCRIPTION: '[data-sticky-checkout-description]',
    DATA_STICKY_CHECKOUT_PRODUCT_LINK: '[data-sticky-checkout-product-link]',
    DATA_STICKY_CHECKOUT_BUY_NOW_LINK: '[data-sticky-checkout-buy-now-link]'

  };

  const debounce = func => {
    let timer;
    return event => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(func, 10, event);
    };
  };

  const isDesktopView = () => window.innerWidth > 1199;

  const addClass = (el, className) => {
    if (!el.classList.contains(className)) {
      el.classList.add(className);
    }
  };

  const removeClass = (el, className) => {
    if (el.classList.contains(className)) {
      el.classList.remove(className);
    }
  };

  const getElementTop = element => element.getBoundingClientRect()?.top;

  const updateStickyComponents = ({
    header,
    extraInfo,
    stickyCheckout,
  }) => {
    const extraInfoTop = getElementTop(extraInfo);
    const headerTop = getElementTop(header);

    const isHeaderSticky = !isDesktopView() && headerTop < 0 && extraInfoTop - header.children[0].offsetHeight > 0;
    const isCheckoutSticky = !isDesktopView() && extraInfoTop - header.children[0].offsetHeight > 0;

    if (isHeaderSticky) {
      header.style.setProperty(
        'flex',
        `0 0 ${header.children[0].offsetHeight}px`
      );
      addClass(header.children[0], CONSTANTS.STICKY_TIER_GROUP);
      // Keep header width the same when position: absolute
      header.children[0].style.setProperty('width', `${header.offsetWidth}px`);
    } else {
      header.style.setProperty('flex', '1');
      removeClass(header.children[0], CONSTANTS.STICKY_TIER_GROUP);
      header.children[0].style.removeProperty('width');
    }

    if (isCheckoutSticky) {
      removeClass(stickyCheckout, CONSTANTS.NONE_STICK_CHECKOUT);
    } else {
      addClass(stickyCheckout, CONSTANTS.NONE_STICK_CHECKOUT);
    }
  };

  const updateStickyCheckoutContents = ({ stickyCheckout, tier }) => {
    const stickyCheckoutNode = stickyCheckout;
    const productName = tier.querySelector(CONSTANTS.PRODUCT_NAME_CLASS)?.innerText || '';
    const productTier = tier.querySelector('.comparison-table__product-tag')?.innerText || '';
    const productLink = tier.querySelector('.comparison-table__btn-group .btn--primary')?.getAttribute('href') || '#';
    const buyNowLink = tier.querySelector('.comparison-table__btn-group .btn--secondary')?.getAttribute('href') || null;

    stickyCheckoutNode.querySelector(
      CONSTANTS.DATA_STICKY_CHECKOUT_DESCRIPTION
    ).innerHTML = `Try <strong>${productTier} ${productName}</strong> FREE for 30 days and get 50% off the subsequent 3 months!`;

    stickyCheckoutNode.querySelector(CONSTANTS.DATA_STICKY_CHECKOUT_PRODUCT_LINK).href = productLink;

    const buyNowButton = stickyCheckoutNode.querySelector(CONSTANTS.DATA_STICKY_CHECKOUT_BUY_NOW_LINK);
    if (buyNowLink) {
      buyNowButton.style.display = 'block';
      buyNowButton.ariaHidden = false;
      buyNowButton.href = buyNowLink;
    } else {
      buyNowButton.style.display = 'none';
      buyNowButton.ariaHidden = true;
    }

    addClass(stickyCheckout, CONSTANTS.SHOW_STICKY_CHECKOUT);
  };

  const resetTableSelection = ({ tiers }) => {
    const removeActiveClass = el => el.classList.remove(CONSTANTS.IS_SELECTED);
    Array.from(tiers).forEach(removeActiveClass);
  };

  const checkTableSelectionClass = el => {
    addClass(el, CONSTANTS.IS_SELECTED);
  };

  const hasPromo = tier => {
    const promoBanner = tier.querySelector(CONSTANTS.PROMOTE_BANNER_CLASS);
    return !!promoBanner;
  };

  const getColumnOffset = comparisonTable => {
    const firstTier = comparisonTable.querySelector(CONSTANTS.SPEC_CLASS);
    return firstTier ? Array.from(firstTier.parentNode.children).indexOf(firstTier) + 1 : 0;
  };

  const addEventListenersToColumns = ({
    comparisonTable,
    stickyCheckout,
    tiers
  }) => {
    const columnOffset = getColumnOffset(comparisonTable);
    Array.from(tiers).forEach((tier, tierIndex) => {
      const column = comparisonTable.querySelectorAll(`${CONSTANTS.ROW_CLASS}>:nth-child(${tierIndex + columnOffset})`);
      const clickHandler = () => {
        // Removing the 'is-selected' class from all previously selected elements
        resetTableSelection({ tiers });
        updateStickyCheckoutContents({ stickyCheckout, tier });
        checkTableSelectionClass(tier);
      };

      // Select defaults
      if (hasPromo(tier)) {
        clickHandler();
      }
      tier.addEventListener('click', clickHandler);

      Array.from(column).forEach(item => {
        item.addEventListener('click', clickHandler);
      });
    });
  };

  const init = () => {
    // Multiple comparison-tables may appear in one page, so we need to loop through all of them.
    // For example: AU comparison table may stay side by side with NZ comparison table in one page
    const comparisonTables = document.querySelectorAll(CONSTANTS.DATA_COMPARISON_TABLE);

    if (comparisonTables.length === 0) {
      return;
    }

    Array.from(comparisonTables).forEach(comparisonTable => {
      const header = comparisonTable.querySelector(CONSTANTS.DATA_TIER_GROUP);
      const tiers = comparisonTable.querySelectorAll(CONSTANTS.TIER_CLASS);
      const rows = comparisonTable.querySelectorAll(CONSTANTS.ROW_CLASS);
      const extraInfo = comparisonTable.querySelector(CONSTANTS.DATA_EXTRA_INFO);
      const stickyCheckout = comparisonTable.querySelector(CONSTANTS.DATA_STICKY_CHECKOUT);

      addEventListenersToColumns({
        comparisonTable,
        stickyCheckout,
        tiers,
        rows
      });
      updateStickyComponents({
        header, extraInfo, stickyCheckout
      });

      window.addEventListener(
        'resize',
        debounce(() => {
          updateStickyComponents({
            header, extraInfo, stickyCheckout
          });
        })
      );

      window.addEventListener(
        'scroll',
        debounce(() => {
          updateStickyComponents({
            header, extraInfo, stickyCheckout
          });
        })
      );
    });
  };

  return {
    init,
    debounce,
    updateStickyComponents,
  };
};

ComparisonTableReaction().init();

export default ComparisonTableReaction;
