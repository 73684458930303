/* global Foundation, jQuery */

import waypointElemActive from '../../assets/js/system/waypoint';

(function myobIconAnimationFactory($, window, Foundation) {
  class MyobIconAnimation {
    constructor(element, options) {
      this.$element = element;
      this.options = $.extend({}, MyobIconAnimation.defaults, this.$element.data(), options);

      Foundation.registerPlugin(this, 'MyobIconAnimation');
      this._events();
    }

    _events() {
      const $fraction = this.$element.find('.stats-fraction');
      const $percentage = this.$element.find('.stats-percentage');
      const $price = this.$element.find('.stats-price');

      $fraction.each((index, element) => {
        const $this = $(element);
        const $text = $this.find('.text__update');
        const $circle = $this.children('.circle');
        const textCountTo = parseInt($text[0].textContent, 10);
        const countTo = parseInt($circle.attr('stroke-dasharray').split(',')[0], 10);

        this.iterateNumber($this, $text, textCountTo, $circle, countTo);
      });

      $price.each((index, element) => {
        const $this = $(element);
        const $text = $this.find('.text__update');
        const textCountTo = parseInt($text[0].textContent, 10);

        this.iterateNumber($this, $text, textCountTo);
      });

      $percentage.each((index, element) => {
        const $this = $(element);
        const $text = $this.find('.text__update');
        const $circle = $this.children('.circle');
        const textCountTo = parseInt($text[0].textContent, 10);
        const countTo = parseInt($circle.attr('stroke-dasharray').split(',')[0], 10);

        this.iterateNumber($this, $text, textCountTo, $circle, countTo);
      });
    }

    resetCount() {
      const resetText = this.$element.find('.text__update');
      const resetStroke = this.$element.find('.circle');

      if (resetText) {
        resetText.each((index, element) => {
          const $this = $(element);
          $this[0].textContent = 0;
        });
      }

      if (resetStroke) {
        resetStroke.each((index, element) => {
          const $this = $(element);
          const strokeDashArray = $this.attr('stroke-dasharray').split(',')[0];
          $this[0].style.strokeDashoffset = strokeDashArray;
        });
      }
    }

    iterateNumber(trigger, number, numberTotal, circle = null, circleTotal = 0) {
      const $number = number;
      const $circle = circle;
      let total;

      if (circleTotal > 0) {
        total = circleTotal;
      } else {
        total = numberTotal;
      }

      $('.waypoint').on('waypoint.on', event => {
        const $waypointElement = $(event.target).find(this.$element);
        if (waypointElemActive($waypointElement)) {
          // TODO: Move this to Green Sock once implemented
          this.resetCount();
          $({ countNum: $number.text })
            .delay(this.options.delay)
            .animate(
              { countNum: total },
              {
                duration: this.options.duration,
                easing: this.options.easing,
                step: count => {
                  if (numberTotal <= 10) {
                    $number.text(Math.floor(count / 10));
                  } else {
                    $number.text(Math.floor(count));
                  }
                  if ($circle) {
                    $circle[0].style.strokeDashoffset = Math.floor(total - count);
                  }
                },
              }
            );
        }
      });
    }
  }

  /* eslint-disable no-mixed-operators, no-plusplus, no-param-reassign */
  MyobIconAnimation.easing = {
    // no easing, no acceleration
    linear(t) {
      return t;
    },
    // accelerating from zero velocity
    easeInQuad(t) {
      return t * t;
    },
    // decelerating to zero velocity
    easeOutQuad(t) {
      return t * (2 - t);
    },
    // acceleration until halfway, then deceleration
    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    // accelerating from zero velocity
    easeInCubic(t) {
      return t * t * t;
    },
    // decelerating to zero velocity
    easeOutCubic(t) {
      return --t * t * t + 1;
    },
    // acceleration until halfway, then deceleration
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    // accelerating from zero velocity
    easeInQuart(t) {
      return t * t * t * t;
    },
    // decelerating to zero velocity
    easeOutQuart(t) {
      return 1 - --t * t * t * t;
    },
    // acceleration until halfway, then deceleration
    easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
    },
    // accelerating from zero velocity
    easeInQuint(t) {
      return t * t * t * t * t;
    },
    // decelerating to zero velocity
    easeOutQuint(t) {
      return 1 + --t * t * t * t * t;
    },
    // acceleration until halfway, then deceleration
    easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
    },
  };
  /* eslint-enable no-mixed-operators, no-plusplus, no-param-reassign */

  MyobIconAnimation.defaults = {
    delay: 0,
    duration: 1100,
    easing: MyobIconAnimation.easing.easeOutQuint(),
  };

  Foundation.plugin(MyobIconAnimation, 'MyobIconAnimation');
}(jQuery, window, Foundation));
