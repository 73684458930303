class Alert {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const googleFormattedData = {};
    switch (this.event) {
      case 'alertClosed': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.alertId = this.data.attributes.alertId;
        googleFormattedData.alertText = this.data.attributes.alertText;

        break;
      }

      case 'alertDisplayed': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.alertId = this.data.attributes.alertId;
        googleFormattedData.alertText = this.data.attributes.alertText;

        break;
      }

      default:
        throw Error(`Event ${this.event} is not registered`);
    }
    return googleFormattedData;
  }
}

export default Alert;
