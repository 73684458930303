class PricingCalculator {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const googleFormattedData = {};

    switch (this.event) {
      case 'frequencyChange': {
        googleFormattedData.action = this.data.eventInfo.eventAction;
        googleFormattedData.category = this.data.attributes.category;
        googleFormattedData.eventName = this.data.eventInfo.eventName;
        googleFormattedData.frequency = this.data.attributes.frequency;
        googleFormattedData.label = this.data.attributes.label;
        googleFormattedData.value = this.data.attributes.value;

        break;
      }

      case 'buyClick': {
        googleFormattedData.action = this.data.eventInfo.eventAction;
        googleFormattedData.category = this.data.attributes.category;
        googleFormattedData.eventName = this.data.eventInfo.eventName;
        googleFormattedData.frequency = this.data.attributes.frequency;
        googleFormattedData.label = this.data.attributes.label;
        googleFormattedData.value = this.data.attributes.value;

        break;
      }

      case 'trialClick': {
        googleFormattedData.action = this.data.eventInfo.eventAction;
        googleFormattedData.category = this.data.attributes.category;
        googleFormattedData.eventName = this.data.eventInfo.eventName;
        googleFormattedData.frequency = this.data.attributes.frequency;
        googleFormattedData.label = this.data.attributes.label;
        googleFormattedData.value = this.data.attributes.value;

        break;
      }

      case 'employeeChange': {
        googleFormattedData.action = this.data.eventInfo.eventAction;
        googleFormattedData.category = this.data.attributes.category;
        googleFormattedData.eventName = this.data.eventInfo.eventName;
        googleFormattedData.frequency = this.data.attributes.frequency;
        googleFormattedData.label = this.data.attributes.label;
        googleFormattedData.value = this.data.attributes.value;

        break;
      }

      default:
        throw Error(`Event ${this.event} is not registered`);
    }
    return googleFormattedData;
  }
}

export default PricingCalculator;
