class PushData {
  constructor() {
    this.window = window || {};
    this.initGoogle();
  }

  setWindow(windowObj) {
    this.window = windowObj;
    this.initGoogle();
  }

  initGoogle() {
    this.window.dataLayer = this.window.dataLayer || [];
    this.dataLayer = this.window.dataLayer;
  }

  pushToGoogle(eventObject) {
    this.dataLayer.push(eventObject);
  }
}

export default PushData;
