class Form {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const googleFormattedData = {};
    switch (this.event) {
      case 'formStart': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.elementId = this.data.attributes.formId;

        break;
      }

      case 'formSubmit': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.elementId = this.data.attributes.formId;
        break;
      }

      case 'formSuccess': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.elementId = this.data.attributes.formId;

        break;
      }

      case 'formError': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.elementId = this.data.attributes.formId;
        googleFormattedData.errorText = this.data.attributes.errorMessage;

        break;
      }

      default:
        throw Error(`Event ${this.event} is not registered`);
    }
    return googleFormattedData;
  }
}

export default Form;
