const toggleContinousAnimation = animationClass => event => {
  if (event.target.classList.contains(animationClass)) {
    event.target.classList.remove(animationClass);
  } else {
    event.target.classList.add(animationClass);
  }
};

const toggleTransitionalAnimation = animationClass => event => {
  event.target.classList.add(animationClass);
  setTimeout(() => event.target.classList.remove(animationClass), 700);
};

Array.from(document.querySelectorAll('button.anim-continuous-toggle')).forEach(elem => {
  const $element = elem;
  $element.onclick = toggleContinousAnimation(elem.dataset.anim);
});

Array.from(document.querySelectorAll('button.anim-transitional-toggle')).forEach(elem => {
  const $element = elem;
  $element.onclick = toggleTransitionalAnimation(elem.dataset.anim);
});
