/* global Foundation, jQuery */

import 'foundation-sites/js/foundation.toggler';
import 'foundation-sites/js/foundation.util.motion';
import 'foundation-sites/js/foundation.util.triggers';
import Cookies from 'js-cookie';
import AnalyticsHelper from '../../assets/js/helpers/analytics/analytics-helper';

(function myobAlertFactory($, Foundation) {
  class MyobAlert extends Foundation.Toggler {
    constructor(element, options) {
      element.data('toggler', element.data('myobAlert'));
      element.attr('data-toggle', '');
      const overrideOptions = $.extend({}, MyobAlert.defaults, element.data(), options);

      super(element, overrideOptions);
      this.options = overrideOptions;

      Foundation.registerPlugin(this, 'MyobAlert');
    }

    _init() {
      this.analyticsHelper = new AnalyticsHelper();

      // Check show/hidden alert
      let cookieName;

      if (this.options.alertId) {
        cookieName = this.getCookieName();
      }

      if (!cookieName || !Cookies.get(cookieName)) {
        this.$element.show();

        this.analyticsHelper.record('AEM:alert:alertDisplayed', {
          eventInfo: {
            eventName: 'alertDisplayed',
          },
          attributes: {
            componentClass: this.options.componentClass,
            _sourceElement: this.$element[0],
            alertId: this.options.alertId,
            alertText: this.$element.find('.alert__copy p').html(),
          },
        });
      } else {
        this.$element.hide();
      }
    }

    _events() {
      super._events();

      const that = this;

      // Alert dismissed
      this.$element.find('.close').on('click', () => {
        that.analyticsHelper.record('AEM:alert:alertClosed', {
          eventInfo: {
            eventName: 'alertClosed',
          },
          attributes: {
            componentClass: that.options.componentClass,
            _sourceElement: that.$element[0],
            alertId: this.options.alertId,
            alertText: this.$element.find('.alert__copy p').html(),
          },
        });

        if (!that.options || !that.options.alertId || !that.options.alertDays) {
          return;
        }

        const cookieName = that.getCookieName();
        const dayNumber = that.options.alertDays;

        Cookies.set(cookieName, true, {
          expires: parseInt(dayNumber, 10),
        });
      });
    }

    getCookieName() {
      return `alert-${this.options.alertId}`;
    }
  }

  MyobAlert.defaults = {
    animate: false,
    componentClass: 'alert',
    alertDays: 7,
  };

  Foundation.plugin(MyobAlert, 'MyobAlert');
}(jQuery, Foundation));
