/* global appConfig */
/* This helper function is used to upload files with the help of filestack */
import * as filestack from 'filestack-js';

const defaults = {
  maxFiles: 5,
  maxSize: 10 * 1024 * 1024,
  maxRandomFilePrefix: 9999,
  minRandomFilePrefix: 1,
  displayMode: 'inline',
  disableTransformer: true,
  container: '.file-inline-upload',
  accept: ['image/*', 'text/*', '.pdf'],
  uploadInBackground: false,
  fromSources: ['local_file_system'],
  uploadFileUrls: [],
  element: {},
  successMessageElement: {},
};

const disableSubmitButton = () => {
  defaults.element.classList.add('is-disabled');
  defaults.element.setAttribute('disabled', true);
};

const updateFileName = file => {
  defaults.successMessageElement.classList.add('no-display');
  const updatedFile = file;
  updatedFile.filename = `${Math.floor(
    Math.random() * (defaults.maxRandomFilePrefix - defaults.minRandomFilePrefix) + defaults.minRandomFilePrefix
  )}_${file.filename}`;
  return file;
};

const updateFileData = result => {
  const uploadFileUrls = [];
  result.filesUploaded.map(fileUploaded => {
    uploadFileUrls.push({ fileName: fileUploaded.filename, fileURL: fileUploaded.url });
    return uploadFileUrls;
  });
  defaults.uploadFileUrls = Array.prototype.concat(defaults.uploadFileUrls, uploadFileUrls);
  defaults.element.classList.remove('is-disabled');
  defaults.element.removeAttribute('disabled');
  if (defaults.successMessageElement) {
    defaults.successMessageElement.classList.remove('no-display');
  }
};

const getUploadFileUrls = () => defaults.uploadFileUrls;

const setOptions = () => ({
  displayMode: defaults.displayMode,
  container: defaults.container,
  accept: defaults.accept,
  disableTransformer: defaults.disableTransformer,
  uploadInBackground: defaults.uploadInBackground,
  maxFiles: defaults.maxFiles,
  maxSize: defaults.maxSize,
  fromSources: defaults.fromSources,
  onFileSelected: updateFileName,
  onUploadDone: updateFileData,
  onFileUploadStarted: disableSubmitButton,
});

const init = (element, successMessageElement) => {
  if (element && successMessageElement) {
    const client = filestack.init(appConfig.fileStack.apiKey);
    const options = setOptions();
    defaults.element = element;
    defaults.successMessageElement = successMessageElement;
    client.picker(options).open();
  }
};

export {
  init as uploadFormHelper, getUploadFileUrls, updateFileName, updateFileData, disableSubmitButton, defaults
};
