/* global Foundation, jQuery */

import 'foundation-sites/js/foundation.tabs';
import 'foundation-sites/js/foundation.util.keyboard';
import 'foundation-sites/js/foundation.util.timerAndImageLoader';

(function myobTabsFactory($, Foundation) {
  const tabIds = [];
  class MyobTabs extends Foundation.Tabs {
    constructor(element, options) {
      const overrideOptions = $.extend(options, MyobTabs.defaults);

      super(element, overrideOptions);
      this.options = overrideOptions;

      Foundation.registerPlugin(this, 'MyobTabs');
    }

    _init() {
      this.$tabsMenu = this.$element.find('.tabs__menu');
      this.$tabsList = this.$element.find('.tabs__list');

      this.$element.find('li a').each((idx, ele) => {
        tabIds.push($(ele).attr('id'));
      });

      this.$tabsMenu[0].setAttribute('data-overflowing', MyobTabs.determineOverflow(this.$tabsList, this.$tabsMenu));

      super._init();
    }

    _events() {
      super._events();

      this.$tabsMenu.on('scroll', () => {
        this.$tabsMenu[0].setAttribute('data-overflowing', MyobTabs.determineOverflow(this.$tabsList, this.$tabsMenu));
      });

      window.addEventListener('resize', () => {
        const DOMChange = window.Site.debounce(() => {
          this.$tabsMenu[0].setAttribute('data-overflowing', MyobTabs.determineOverflow(this.$tabsList, this.$tabsMenu));
        }, 250);
        DOMChange();
      });
    }

    static determineOverflow(content, container) {
      if (content[0] && container[0]) {
        const containerMetrics = container[0].getBoundingClientRect();
        const containerMetricsRight = Math.floor(containerMetrics.right);
        const containerMetricsLeft = Math.floor(containerMetrics.left);
        const contentMetrics = content[0].getBoundingClientRect();
        const contentMetricsRight = Math.floor(contentMetrics.right);
        const contentMetricsLeft = Math.floor(contentMetrics.left);

        if (containerMetricsLeft > contentMetricsLeft
          && containerMetricsRight < contentMetricsRight) {
          return 'both';
        } if (contentMetricsLeft < containerMetricsLeft) {
          return 'left';
        } if (contentMetricsRight > containerMetricsRight) {
          return 'right';
        }
      }
      return 'none';
    }
  }

  MyobTabs.defaults = {
    linkClass: 'tabs__title',
    linkActiveClass: 'is-active',
    panelClass: 'tabs__panel',
    panelActiveClass: 'is-active',
    componentClass: 'tabs',
    slideSpeed: 250,
    deepLink: true
  };

  Foundation.plugin(MyobTabs, 'MyobTabs');
}(jQuery, Foundation));
