import svg4everybody from 'svg4everybody';

export default () => {
  // Enable SVGs referencing external links via map for IE11
  svg4everybody();

  /**
  * Inject gradient definitions for SVG icons
  * This attaches a node at the end of the body that looks like:
  * <svg height="0" id="icon-gradient-container">
  *   <defs>
  *     <linearGradient id="icon-gradient">
  *       <stop offset="0%" stop-color="#6100A5"></stop>
  *       <stop offset="100%" stop-color="#EC0677"></stop>
  *     </linearGradient>
  *   </defs>
  * </svg>
  */
  const existingGradientNode = document.getElementById('icon-gradient');
  if (!existingGradientNode) {
    // Helper methods
    const createSVGElement = tagName => document.createElementNS('http://www.w3.org/2000/svg', tagName);
    const createStop = (offset, color) => {
      const stop = createSVGElement('stop');
      stop.setAttribute('offset', offset);
      stop.setAttribute('stop-color', color);
      return stop;
    };

    // Create the gradient
    const defs = createSVGElement('defs');
    const linearGradient = createSVGElement('linearGradient');
    linearGradient.id = 'icon-gradient';
    const stop1 = createStop('0%', '#6100A5');
    linearGradient.appendChild(stop1);
    const stop2 = createStop('100%', '#EC0677');
    linearGradient.appendChild(stop2);
    defs.appendChild(linearGradient);

    const newGradientContainer = createSVGElement('svg');
    // Ensure this doesn't show - don't use display none as some browsers ignore
    newGradientContainer.setAttribute('class', 'global-ui-icons');
    newGradientContainer.id = 'icon-gradient-container';
    newGradientContainer.appendChild(defs);
    document.body.appendChild(newGradientContainer);
  }
};
