// Expand an element
const expand = elem => {
  const $el = elem;
  const getHeight = () => {
    $el.style.display = 'block';
    const height = `${$el.scrollHeight}px`;
    $el.style.display = '';
    return height;
  };

  $el.style.height = getHeight();

  window.setTimeout(() => {
    $el.style.height = '';
  }, 350);

  $el.classList.add('is-visible');
};

// Collapse an element
const collapse = elem => {
  const $el = elem;

  $el.style.height = `${$el.scrollHeight}px`;

  window.setTimeout(() => {
    $el.style.height = '0';
  }, 1);

  window.setTimeout(() => {
    $el.classList.remove('is-visible');
  }, 350);
};

// Show an element
const show = elem => {
  const $el = elem;

  $el.classList.add('is-visible');
};

// Hide an element
const hide = elem => {
  const $el = elem;

  $el.classList.remove('is-visible');
};

const toggleMenuItems = () => {
  // Listen for click events
  document.addEventListener('click', event => {
    if (event.target.hasAttribute('data-mds-toggle')) {
      event.preventDefault();

      const content = document.querySelector(event.target.dataset.mdsTarget);
      const interaction = event.target.dataset.mdsToggle;

      if (!event.target.classList.contains('is-active')) {
        event.target.classList.add('is-active');

        if (event.target.dataset.mdsOverlay) {
          document.body.classList.add('overlay');
        }
      } else {
        event.target.classList.remove('is-active');

        if (event.target.dataset.mdsOverlay) {
          document.body.classList.remove('overlay');
        }
      }

      if (!content) return;

      if (interaction === 'collapse') {
        if (content.classList.contains('is-visible')) {
          collapse(content);
          return;
        }

        expand(content);
      }

      if (interaction === 'toggle') {
        if (content.classList.contains('is-visible')) {
          hide(content);
          return;
        }

        show(content);
      }
    }
  }, false);
};

const scrollToActive = () => {
  const activeElem = document.querySelector('.mds-menu__sub-link--active');
  if (activeElem) activeElem.scrollIntoView();
};

if (document.readyState !== 'loading') {
  scrollToActive();
  toggleMenuItems();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    scrollToActive();
    toggleMenuItems();
  });
}

// Reset for desktop
['load', 'resize'].forEach(event => {
  window.addEventListener(event, () => {
    const DOMChange = window.Site.debounce(() => {
      const largeScreenWidth = 1200;
      const sidebar = document.getElementById('mds-sidebar');
      const trigger = document.getElementById('mds-trigger');

      if (window.innerWidth >= largeScreenWidth && sidebar && trigger) {
        document.body.classList.remove('overlay');

        if (sidebar && sidebar.classList && typeof sidebar.classList.remove === 'function') {
          sidebar.classList.remove('is-visible');
        }
        if (trigger && trigger.classList && typeof trigger.classList.remove === 'function') {
          trigger.classList.remove('is-active');
        }
      }
    }, 250);

    DOMChange();
  });
});
