import { registerPluginNames } from '../../assets/js/system/registrar';
import { isActive } from '../../assets/js/helpers/state-helper';
import { getViewport, isGreaterThan } from '../../assets/js/helpers/window-helper';

import {
  show, hide, makeActive, makeInactive, collapseTarget, expandTarget
} from './accordion.helpers';

const defaults = {
  openClass: 'is-open',
  closeClass: 'is-closed',
  transitionClass: 'transitioning'
};

let lastViewport;

const defaultValue = (checkVar, defaultVar) => (checkVar || defaultVar);

/**
 * Sets the initial state (expanded or collapsed) of each accordion item that is defined
 * @param {Object} elementData
 */
const setInitialState = elementData => {
  elementData.forEach(({
    items, settings, transitionClass, openOn, singleExpand
  }) => {
    if (isGreaterThan(openOn, true) && openOn) {
      let itemsToOpen = Array.from(items);

      // If only allowing a single item to be open at once,
      // then ensure others are hidden
      if (singleExpand && itemsToOpen.length > 0) {
        itemsToOpen = [itemsToOpen[0]];
        Array.from(items).filter(
          ({ toggle }) => toggle !== itemsToOpen[0].toggle
        ).forEach(({ toggle, target }) => {
          hide(toggle, target, settings, transitionClass);
        });
      }

      // Open items when openOn specified
      Array.from(itemsToOpen).forEach(({ toggle, target }) => {
        target.classList.remove(settings.closeClass);
        makeActive(toggle);
        show(toggle, target, settings, transitionClass);
      });
    } else {
      // By default items are hidden by default
      Array.from(items).forEach(({ toggle, target }) => {
        target.classList.remove(settings.openClass);
        hide(toggle, target, settings, transitionClass);
        makeInactive(toggle);
      });
    }
  });
};

/**
 * Adds the wanted event types and associated handlers to the defined elements
 * @param {Object} elementData
 */
const addEventListeners = elementData => {
  // Root element data
  elementData.forEach(({
    items, transitionClass, settings, singleExpand
  }) => {
    Array.from(items).forEach(({ toggle, target }) => {
      toggle.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();

        // If the target is already transitioning don't do anything
        if (!target.classList.contains(transitionClass)) {
          // When open, close it
          if (isActive(toggle)) {
            collapseTarget(toggle, target, settings, transitionClass);

          // When closed, open it
          } else {
            expandTarget(toggle, target, settings, transitionClass);

            // If only allowing a single item open, we need to close the others
            if (singleExpand) {
              items.filter(
                ({ toggle: itemToggle }) => itemToggle !== toggle && isActive(itemToggle)
              ).forEach(({
                toggle: itemToggle, target: itemTarget
              }) => {
                collapseTarget(itemToggle, itemTarget, settings, transitionClass);
              });
            }
          }
        }
      });
    });
  });
};

/**
 * Returns and maps all Nodes within the given NodeList
 * elementData returns a list of objects that contain the following information:
 *    accordion:        the element that contains the data-myob-accordion attribute
 *    items:            a list of toggles and targets within the accordion root Node
 *    transitionClass:  the class that contains the transition styles
 *    openOn:           the breakpoint from small to xxlarge where the accordion is open by default
 *    singleExpand:     if true, allow only one open accordion item at a time
 * @param {NodeList} elements
 */
const init = elements => {
  const elementData = Array.from(elements).map(element => {
    const settings = {
      openClass: defaults.openClass,
      closeClass: defaults.closeClass,
      transitionClass: defaults.transitionClass,
    };
    return ({
      accordion: element,
      items: Array.from(element.querySelectorAll('[data-accordion-item]')).map(item => {
        const [toggle] = item.querySelectorAll('[data-accordion-toggle]');
        const target = document.getElementById(toggle.dataset.accordionTarget);
        return {
          toggle,
          target,
        };
      }),
      settings,
      transitionClass: defaultValue(element.dataset.accordionTrans, settings.transitionClass),
      openOn: element.dataset.accordionOpenOn,
      singleExpand: ('accordionSingleExpand' in element.dataset) && (element.dataset.accordionSingleExpand !== 'false')
    });
  });

  addEventListeners(elementData);
  setInitialState(elementData);
  window.addEventListener('resize', window.Site.debounce(() => {
    if (getViewport() !== lastViewport) {
      setInitialState(elementData);
      lastViewport = getViewport();
    }
  }, 250));
};

export default init;
export { addEventListeners, setInitialState, defaultValue };
registerPluginNames(init, 'accordion');
