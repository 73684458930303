import {
  addActiveState, removeActiveState,
} from '../../assets/js/helpers/state-helper';

import { addTransition, removeTransition } from '../../assets/js/helpers/transition-helper';

/**
 * Sets the given toggles state to inactive
 * @param {HTMLElement} toggle
 */
const makeInactive = toggle => {
  removeActiveState(toggle);
  toggle.setAttribute('aria-expanded', 'false');
};

/**
 * Sets the given toggles state to active
 * @param {HTMLElement} toggle
 */
const makeActive = toggle => {
  addActiveState(toggle);
  toggle.setAttribute('aria-expanded', 'true');
};

/**
 * Modifies the given item to show its content
 * @param {HTMLElement} toggle
 * @param {HTMLElement} target
 * @param {String} transitionClass
 */
const show = (toggle, target, settings, transitionClass) => {
  target.classList.add(settings.openClass);
  removeTransition(target, transitionClass);
  target.style.height = ''; // eslint-disable-line no-param-reassign
};

/**
 * Modifies the given item to hide its content
 * @param {HTMLElement} toggle
 * @param {HTMLElement} target
 * @param {String} transitionClass
 */
const hide = (toggle, target, settings, transitionClass) => {
  removeTransition(target, transitionClass);
  target.classList.add(settings.closeClass);
};

/**
 * Runs through the collapsing transition on the given item
 * The function parameters are being passed to addTranstion, which requires a pre-transition, intra-transiton and post-transition functions.
 * @param {HTMLElement} toggle
 * @param {HTMLElement} target
 * @param {String} transitionClass
 */
const collapseTarget = (toggle, target, settings, transitionClass) => {
  addTransition(target, transitionClass,
    () => {
      target.style.height = `${target.scrollHeight}px`; // eslint-disable-line no-param-reassign
    },
    () => {
      target.classList.remove(settings.openClass);
      target.style.height = ''; // eslint-disable-line no-param-reassign
    },
    () => {
      makeInactive(toggle);
      hide(toggle, target, settings, transitionClass);
    });
};

/**
 * Runs through the expanding transition on the given item
 * The function parameters are being passed to addTranstion, which requires a pre-transition, intra-transiton and post-transition functions.
 * @param {HTMLElement} toggle
 * @param {HTMLElement} target
 * @param {String} transitionClass
 */
const expandTarget = (toggle, target, settings, transitionClass) => {
  let height = 0;

  makeActive(toggle);
  addTransition(target, transitionClass,
    () => {
      target.classList.remove(settings.closeClass);
      height = `${target.scrollHeight}px`;
    },
    () => {
      target.style.height = height; // eslint-disable-line no-param-reassign
    },
    () => {
      show(toggle, target, settings, transitionClass);
    });
};

export {
  hide, show, makeActive, makeInactive, collapseTarget, expandTarget
};
