/**
 * Purpose: A re-usable plugin to enable elements to become sortable.
 * In this context,
 *   a sort control is a drop-down menu that is used to determine what sorting is used
 *   items are the things that are being sorted
 */

import { registerPluginNames } from '../../assets/js/system/registrar';

const defaults = {
  sortItem: '',
  sortText: ''
};

const pluginName = 'sort';

/**
 * Return value depending on the values of a and b.
 * @param {String} a  The first string to compare
 * @param {String} b  The second string to compare
 * @return {Number}   Returns sorting order value position
 */
const sortZA = (a, b) => {
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  return 0;
};

/**
 * Return value depending on the values of a and b.
 * @param {String} a  The first string to compare
 * @param {String} b  The second string to compare
 * @return {Number}   Returns sorting order value position
 */
const sortAZ = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

/**
 * Sorts the items baced on the sortFunction (if provided), then rearranges the DOM accordingly.
 * @param {Object} element          A collection of Configurations and Node Lists
 * @param {Function} sortFunction   A function that is pre-sepcified to be used to sort the element Node Lists
 */
const moveItems = (element, sortFunction) => {
  const parents = element.itemParents;
  const original = element.items.slice(0);
  const copy = element.items.slice(0);

  let sorted = [];

  if (sortFunction) {
    sorted = copy.sort((a, b) => {
      const elem1 = a.querySelectorAll(element.defaults.sortText)[0];
      const elem2 = b.querySelectorAll(element.defaults.sortText)[0];

      let text1 = '';
      let text2 = '';

      if (elem1) {
        text1 = elem1.textContent.toLowerCase();
      }

      if (elem2) {
        text2 = elem2.textContent.toLowerCase();
      }

      return sortFunction(text1, text2);
    });
  } else {
    sorted = original;
  }

  parents.forEach((parent, index) => {
    if (parent && sorted[index]) {
      parent.appendChild(sorted[index]);
    }
  });
};

/**
 * Adds event listeners to controls in each sort control instance
 * @param {Object[]} controlInstances Sort control instance object
 */
const addEventListeners = (controlInstances, action) => {
  if (action === 'change') {
    Array.from(controlInstances).forEach(controlData => {
      if (controlData.defaults.sortItem && controlData.defaults.sortText) {
        controlData.control.addEventListener('change', e => {
          if (e.target.value === 'default') {
            moveItems(controlData, undefined);
          }

          if (e.target.value === 'ab') {
            moveItems(controlData, sortAZ);
          }

          if (e.target.value === 'ba') {
            moveItems(controlData, sortZA);
          }
        });
      }
    });
  }
};

/**
 * Checks the configuration is complete otherwise chucks the default values in to configuration.
 * @param {Object} elemDataSet the configuration for the sort control
 */
const getDefaults = elemDataSet => {
  // Check each element dataset obj is set to a value otherwise overrite it with the default value.
  const newDataSet = {};
  Object.keys(elemDataSet).forEach(key => {
    if (elemDataSet[key] !== null) {
      newDataSet[key] = elemDataSet[key];
    }
  });
  const originalDataSet = { ...defaults };
  return { ...originalDataSet, ...newDataSet };
};

/**
 * Sets up the configuration for each sort control from the data-attributes.
 * @param {HTMLElement} element A sort control DOM element
 */
const constructElementDataSet = element => {
  const sortItem = element.getAttribute('data-sort-item');
  const sortText = element.getAttribute('data-sort-text');
  return { sortItem, sortText };
};

/**
 * Initialise filter plugin for all elements
 * @param  {NodeList} elements HTMLElements to initialise filter plugin on
 */
const init = plugins => {
  Array.from(plugins).forEach(plugin => {
    // Loop though each sort plugin that was found.
    const controls = plugin.querySelectorAll(`[data-${pluginName}-control]`);
    const controlInstances = Array.from(controls).map(control => {
      const defaultsLocal = getDefaults(constructElementDataSet(control));
      const items = Array.from(plugin.querySelectorAll(defaultsLocal.sortItem));
      return {
        control,
        items,
        itemParents: items.map(item => item.parentNode),
        defaults: defaultsLocal
      };
    });

    addEventListeners(controlInstances, 'change');
  });
};
export default init;

registerPluginNames(init, pluginName);
